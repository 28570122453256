<template>
  <div class="flxcolumn body roundcorner">
    <div v-if="(title !== null && title !== undefined && title !== '') || (sources !== null && sources !== undefined && sources.length > 0)"
        class="title">
      {{ title }}
      <div v-if="recordApiPath !== null && recordApiPath !== undefined && recordApiPath !== ''"
        class="record-btn link-text" @click="showRecordForm">Record Data</div>
      <DataSource :sources="sourceUrls" />
    </div>
    <div v-if="loadSuccess" class="height100p">
      <CommonChart v-if="!usingX"
        style="width: 100%;" class="height100p"
        :title="title"
        :data="indicatorData"
        :legend="legend"
        :x-format="'day'"
        :show-value="showValue"
      />
      <CommonChartX v-else
        :title="title" class="height100p"
        :data="indicatorData"
        :sources="sources"
        :legend="legend"
        :x-format="'day'"
        :show-value="showValue"
        :type="type"
        :rangeSelector="rangeSelector"
        :rangeSelectorSelected="rangeSelectorSelected"
      />
    </div>

    <a-modal
        :visible="modalVisible" @ok="onModalOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        >
      <a-date-picker style="width: 240px; margin: 0px 0px 8px 0px;" v-model="recordDate" />
      <a-textarea v-model="recordContent" placeholder="Input Date" :rows="8" />

      <div class="flxrow margintb2">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onCancel" > Cancel </button>
          <button style="margin-left: 24px;"
            class="btn btn-p1 bg-gradient-dark" @click="onSave" > Save </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import CommonChart from '@/components/charts/CommonChart.vue';
import CommonChartX from '@/components/charts/CommonChartX.vue';
import { getValue, getValues, getDef, } from '@/script/IndicatorAPIs';
import DataSource from '@/components/charts/DataSource.vue';
import moment from 'moment';
import { doPostN, doGetN, } from '@/script/ServerTools';
import { timestampToFormatDateTime, timestampToFormatDate2, } from '@/script/DateTools';

export default {
  name: 'IndicatorChart',
  props: [ 'title', 'indicators', 'type', 'showValue', 'legend', 'rangeSelector', 'rangeSelectorSelected', 'recordApiPath', ],
  components: { CommonChart, CommonChartX, DataSource, },
  data() {
    return {
      indicatorData: [ ],
      sources: [ ],
      loadSuccess: false,
      loadProcess: -1,
      modalVisible: false,
      recordDate: null,
      recordContent: '',
    };
  },
  computed: {
    usingX() {
      if (this.rangeSelector !== null && this.rangeSelector !== undefined) {
        return true;
      }
      if (this.rangeSelectorSelected !== null && this.rangeSelectorSelected !== undefined) {
        return true;
      }
      return false;
    },
    sourceUrls() {
      if (this.sources.length <= 3) {
        return this.sources;
      }
      return this.sources.slice(0, 3);
    },
  },
  methods: {
    load() {
      if (this.indicators === null) {
        setTimeout(() => this.load(), 500);
        return;
      }

      this.loadProcess = this.indicators.length;
      this.indicatorData = [ ];
      this.sources = [ ];

      this.indicators.forEach((indicator) => {
        const code = indicator.code;
        getDef(code).then((rsp) => {
          const def = rsp.result;
          if (def.source !== null && def.source !== undefined && def.source !== '') {
            this.sources.push(def.source);
          }
          getValues(code, indicator.start, indicator.end).then((response) => {
            const data = [ ];
            response.result.forEach((it) => {
              data.push([it.timestamp * 1000, it.value]);
            });
            this.indicatorData.push({
              def,
              name: indicator.title || def.name,
              data,
              sideChart: indicator.sideChart,
              yAxisSide: indicator.yAxis === 'right' ? 'right' : 'left',
              yAxisReverse: indicator.yAxis === 'reverse',
              type: indicator.type ? indicator.type : 'line',
              color: indicator.color,
              stack: indicator.stack,
            });
            this.process();
          });
        });
      });
    },
    process() {
      this.loadProcess -= 1;
      if (this.loadProcess === 0) {
        this.loadSuccess = true;
      }
    },
    showRecordForm() {
      this.modalVisible = true;
      this.recordContent = '';
      this.recordDate = moment();
    },
    onModalOk() {
      this.modalVisible = false;
    },
    onCancel() {
      this.modalVisible = false;
    },
    onSave() {
      doPostN('/api/morpho/data_record' + this.recordApiPath, {
        date: timestampToFormatDateTime(this.recordDate.unix()), content: this.recordContent,
      }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.modalVisible = false;
        this.load();
      });
    },
  },
  created() {
    this.load();
  },
  mounted() {
  },
};
</script>

<style scoped>
.body {
  padding: 8px 8px 0px 8px;
  background-color: #ffffff;
}
.title {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  position: relative;
  min-height: 20px;
}
.record-btn {
  position: absolute;
  left: 16px;
  top: 3px;
  font-size: 13px;
  z-index: 1000;
}
.source {
  position: absolute;
  right: 16px;
  top: 3px;
  font-size: 13px;
  z-index: 1000;
}
</style>
