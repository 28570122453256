<template>
  <div>
    <!-- header -->
    <div style="display: flex;">
      <span v-if="config.title && !hideTitle" style="fontSize: 16px; padding-bottom: 8px;">
        <strong>{{ config.title }}</strong>
      </span>
      <!-- 编辑态下加上编辑按钮header -->
      <div v-if="isEditting" style="margin-left: auto; display: flex;">
        <mr-icon-button name="Settings" icon="tools" size="11px"
            @click="on_editChartConfig(config.id)">
        </mr-icon-button>
        <mr-icon-button name="Delete" icon="trash-fill" size="11px"
            @click="on_deleteChart(config.id)" >
        </mr-icon-button>
      </div>
      <div v-else style="margin-left: auto; display: flex;">
        <mr-icon-button name="" icon="pencil-square" size="12px" @click="on_showMarkdownConfigDialog">
        </mr-icon-button>
        <mr-icon-button name="" icon="nut" size="12px" @click="on_openInteractSetting(chartConfig)"> </mr-icon-button>
      </div>
    </div>

    <!-- Markdown -->
    <markdown-it-vue
        :content="text ? text : ''"
        :options="markdownRenderOptions"
        :ref="config.id"
        />

    <!-- NodeChart
    <chart-node-display
        v-if="chartConfig.chartType==='NodeChart'"
        :streamId="chartConfig.streamId"
        :nodeId="chartConfig.nodeId"
        :showConfig="false"
        :ref="chartConfig.id"
        />
    -->

    <a-modal :visible="markdownConfigDialogVisible"
        :closable="false" :width="1000"
        :footer="null"
        @cancel="on_closeMarkdownConfig"
        >
      <mavon-editor
          ref="mavonEditor" v-model="editorText"
          @save="on_saveMarkdown" @imgAdd="on_uploadImage"/>
    </a-modal>
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue';
import MrIconButton from '@/components/common/MrIconButton.vue';
import {
  remote_updateMarkdownText,
} from '@/script/PageAPIs';
export default {
  name: 'MarkdownChart',
  props: [ 'config', 'isEditting', 'hideTitle', ],
  components: { MarkdownItVue, MrIconButton, },
  data() {
    return {
      text: '',
      editorText: '',

      markdownConfigDialogVisible: false,
      markdownRenderOptions: {
        markdownIt: {
          html: true,
          linkify: true,
        },
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener',
          },
        },
      },
    };
  },
  methods: {
    on_editChartConfig(chartId) {
      console.log('on_editChartConfig');
      this.$emit('editChartConfig', chartId);
    },
    on_deleteChart(chartId) {
      console.log(`on_editChartConfig ${chartId}`);
      this.$emit('deleteChart', chartId);
    },
    on_showMarkdownConfigDialog() {
      this.editorText = this.text;
      this.markdownConfigDialogVisible = true;
    },
    on_closeMarkdownConfig() {
      this.text = this.editorText;
      this.markdownConfigDialogVisible = false;
      remote_updateMarkdownText(this.config.id, this.editorText);
    },
    on_saveMarkdown() {
      remote_updateMarkdownText(this.config.id, this.editorText);
    },
    on_uploadImage() {
    },
  },
  mounted() {
    this.text = this.config.text;
  },
};
</script>

<style scoped>

</style>
