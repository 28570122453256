<template>
  <div id="body">

    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            {{ pageTitle }}
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <el-button v-show="isEditting" size="mini" type="primary" round @click="on_showAddForm">
              + Chart
            </el-button>
            <el-button v-show="isEditting" size="mini" type="primary" round @click="logic_savePageLayout">
              Save Layout
            </el-button>
            <el-button size="mini" type="primary" round @click="on_editPage">
              {{ isEditting ? 'Done' : 'Edit' }}
            </el-button>
          </div>
        </div>

        <nested-drag-chart
            :value="charts" :isEditting="isEditting" :level="0"
            :pageEvents="pageEvents"
            :pageVariables="pageVariables"
            @addChildChart="on_showAddFormForContainer"
            @addPageEvent="on_addPageEvent"
            @addPageVariable="on_addPageVariable"
            @editChartConfig="on_editChartConfig"
            @deleteChart="on_deleteChart"
            >
        </nested-drag-chart>

      </div>
    </div>

    <!-- form modal -->
    <a-modal
        :title="formTitle"
        :visible="addDialogVisible"
        :footer="null"
        class="local-modal"
        @cancel="on_closeAddForm"
        >
      <a-form-model :model="addFormData"
          :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="Title" >
          <a-input v-model="addFormData.title"/>
        </a-form-model-item>
        <a-form-model-item label="Type">
          <a-select v-model="addFormData.chartType" :options="chartTypes">
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Stream Node" v-if="addFormData.chartType==='NodeChart'">
          <a-select show-search v-model="addFormData.streamId" style="width: 150px;"
              @search="on_searchStream"
              @dropdownVisibleChange="on_searchStream('')"
              :options="searchedStreams"
              >
          </a-select>
          <a-select show-search v-model="addFormData.nodeId" style="width: 150px; margin-left: 12px;"
              @search="on_searchStreamNode"
              @dropdownVisibleChange="on_searchStreamNode('')"
              :options="searchedStreamNodes"
              >
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Call Mode" v-if="addFormData.chartType==='NodeChart'">
          <a-select v-model="addFormData.callMode" :options="callModeOptions">
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Width">
          <a-input v-model="addFormData.width">
            <a-select slot="addonAfter" v-model="addFormData.widthUnit" default-value="%" style="width: 80px">
              <a-select-option value="%"> % </a-select-option>
              <a-select-option value="px"> px </a-select-option>
            </a-select>
          </a-input>
        </a-form-model-item>

        <a-form-model-item label="Height" extra="0 for suto size">
          <a-input v-model="addFormData.height" addon-after="px" />
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 5 }">
          <a-button @click="on_closeAddForm"> Cancel </a-button>
          <a-button style="margin-left: 12px;" type="primary" @click="on_submitAddOrUpdate">
            Submit
          </a-button>
        </a-form-model-item>
      </a-form-model>

    </a-modal>

  </div>
</template>

<script>
import NestedDragChart from '@/components/deprecated/NestedDragChart.vue';
import { uuid4 } from '@/script/CommonTools';
import {
  remote_queryPageById, remote_createChart, remote_queryPageCharts,
  queryChartsRecurse, remote_updatePageLayout, remote_savePage, remote_saveChart,
  remote_deleteChart,
} from '@/script/PageAPIs';
import {
  queryStreamByName, queryNodeByName, queryStreamById, remote_queryNode,
} from '@/script/StreamOperations';
import { PageEventBus } from '@/script/PageEventBus';
import { deprecate } from 'util';

export default {
  name: 'Page',
  components: { NestedDragChart, },
  data() {
    return {
      pageTitle: '',
      isEditting: false,
      addDialogVisible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      formTitle: 'Add Chart',
      addFormData: {
        id: '',
        title: '',
        chartType: 'MarkdownText',
        width: 50,
        widthUnit: '%',
        height: 0,
        streamId: '',
        nodeId: '',
        callMode: 'NON_REALTIME',
      },
      searchedStreams: [ ],
      searchedStreamNodes: [ ],
      chartTypes: [
        { value: 'IndicatorChart', label: 'IndicatorChart' },
        { value: 'MarkdownText', label: 'Markdown Text' },
        { value: 'Container', label: 'Container' },
        { value: 'TabContainer', label: 'Tab Container' },
        { value: 'NodeChart', label: 'NodeChart' },
      ],
      callModeOptions: [
        { value: 'NON_REALTIME', label: '非实时' },
        { value: 'REALTIME', label: '实时' },
      ],
      charts: [ ],
      // 页面事件
      pageEvents: { },
      // 页面变量
      pageVariables: { },
    };
  },
  methods: {
    on_childTriggePageEvent(data) {
      console.log('on_childTriggePageEvent');
      console.log(data);
      if (data.pageVariableName) {
        this.pageVariables[data.pageVariableName] = data.pageVariableValue;
      }
      // 触发事件下行
      PageEventBus.$emit('PageEventDown', data);
    },
    on_editPage() {
      this.isEditting = !this.isEditting;
    },
    initAddForm() {
      this.formTitle = 'Add Chart';
      this.addFormData = {
        id: '',
        title: '',
        chartType: 'MarkdownText',
        width: 50,
        widthUnit: '%',
        height: 0,
        streamId: '',
        nodeId: '',
        callMode: 'NON_REALTIME',
        containerId: '',
      };
    },
    on_showAddForm() {
      this.initAddForm();
      this.addDialogVisible = true;
    },
    on_showAddFormForContainer(id) {
      this.initAddForm();
      this.addFormData.containerId = id;
      this.addDialogVisible = true;
    },
    on_closeAddForm() {
      this.addDialogVisible = false;
    },
    logic_findChartById(charts, chartId) {
      for (const it of charts) {
        if (it.id === chartId) {
          return it;
        }
        if (it.children !== undefined && it.children.length > 0) {
          const result = this.logic_findChartById(it.children, chartId);
          if (result !== undefined) {
            return result;
          }
        }
      }
      return undefined;
    },
    on_editChartConfig(chartId) {
      const chart = this.logic_findChartById(this.charts, chartId);
      this.addFormData = { ...chart };
      this.formTitle = 'Update Chart';
      if (this.addFormData.streamId) {
        queryStreamById(this.addFormData.streamId).then((result) => {
          this.searchedStreams = [ { value: result.data._id, label: result.data.name } ];
        });
      }
      if (this.addFormData.nodeId) {
        remote_queryNode(this.addFormData.nodeId).then((result) => {
          this.searchedStreamNodes = [ { value: result.data._id, label: result.data.name } ];
        });
      }
      this.addDialogVisible = true;
    },
    on_deleteChart(chartId) {
      remote_deleteChart(chartId).then((result) => {
        this.charts = this.logic_removeFromCharts(this.charts, chartId);
        this.logic_savePageLayout();
        this.$message({ message: 'Delete success', type: 'success' });
      });
    },
    logic_removeFromCharts(charts, chartId) {
      const res = [ ];
      for (const it of charts) {
        if (it.id !== chartId) {
          res.push(it);
        }
        if (it.children !== undefined && it.children.length > 0) {
          it.children = this.logic_removeFromCharts(it.children, chartId);
        }
      }
      return res;
    },
    on_searchStream(value) {
      queryStreamByName(value).then((result) => {
        const l = [ ];
        result.data.forEach((it) => {
          l.push({ label: it.name, value: it._id });
        });
        this.searchedStreams = l;
      });
    },
    on_searchStreamNode(value) {
      if (this.addFormData.streamId) {
        queryNodeByName(this.addFormData.streamId, value).then((result) => {
          const l = [ ];
          result.data.forEach((it) => {
            l.push({ label: it.name, value: it._id });
          });
          this.searchedStreamNodes = l;
        });
      }
    },
    on_submitAddOrUpdate() {
      if (this.addFormData.id) {
        this.submitUpdateChart();
      } else {
        this.submitAddChart();
      }
    },
    submitUpdateChart() {
      console.log(this.addFormData);
      remote_saveChart(this.addFormData).then((result) => {
        this.$message({ message: result.message, type: 'success' });
        location.reload();
      });
    },
    submitAddChart() {
      const chart = {
        title: this.addFormData.title,
        chartType: this.addFormData.chartType,
        width: this.addFormData.width,
        widthUnit: this.addFormData.widthUnit,
        callMode: this.addFormData.callMode,
        height: this.addFormData.height,
        streamId: this.addFormData.streamId,
        nodeId: this.addFormData.nodeId,
        children: [ ],
      };
      remote_createChart(this.pageId, chart).then((result) => {
        chart.id = result.data._id;
        if (this.addFormData.containerId) {
          this.logic_addChildToContainer(this.charts, this.addFormData.containerId, chart);
        } else {
          this.charts.push(chart);
        }
        this.addDialogVisible = false;
        this.logic_clearAddForm();
        this.logic_savePageLayout();
      });
    },
    // =========================================
    // events & variables
    // =========================================
    on_addPageEvent(eName) {
      if (!(eName in this.pageEvents)) {
        this.pageEvents[eName] = { };
      }
      remote_savePage(this.pageId, { pageEvents: Object.keys(this.pageEvents) });
    },
    on_addPageVariable(vName) {
      if (!(vName in this.pageVariables)) {
        this.pageVariables[vName] = { };
      }
      remote_savePage(this.pageId, { pageVariables: Object.keys(this.pageVariables) });
    },
    // =========================================
    //
    // =========================================
    logic_clearAddForm() {
      this.addFormData = {
        containerId: '',
        title: '',
        chartType: 'Container',
        width: 50,
        widthUnit: '%',
        height: 0,
      };
    },
    logic_addChildToContainer(list, containerId, child) {
      for (const it of list) {
        if (it.chartType === 'Container') {
          console.log(1111, it.id);
          if (it.id === containerId) {
            console.log(222);
            if (!it.children) {
              it.children = [ ];
            }
            it.children.push(child);
            return true;
          }
          if (it.children) {
            const found = this.logic_addChildToContainer(it.children, containerId, child);
            if (found) {
              return found;
            }
          }
        }
      }
      return false;
    },
    logic_queryPageData() {
      remote_queryPageById(this.pageId).then((result) => {
        this.pageTitle = result.data.title;
        this.pageEvents = result.data.page_events.reduce((acc, cur, i) => {
          acc[cur] = { };
          return acc;
        }, {});
        this.pageVariables = result.data.page_variables.reduce((acc, cur, i) => {
          acc[cur] = { };
          return acc;
        }, {});
        if (result.data.layout) {
          const layout = JSON.parse(result.data.layout);
          queryChartsRecurse(this.pageId, layout).then((chartsData) => {
            this.charts = chartsData;
          });
        } else {
          this.charts = [ ];
        }
      });
    },
    logic_savePageLayout() {
      remote_updatePageLayout(this.pageId, this.logic_buildLayoutRecurse(this.charts));
    },
    logic_buildLayoutRecurse(list) {
      const result = [ ];
      list.forEach((it) => {
        result.push({
          id: it.id,
          children: it.children ? this.logic_buildLayoutRecurse(it.children) : [ ],
        });
      });
      return result;
    },
  },
  mounted() {
    this.pageId = this.$route.params.pageid;
    PageEventBus.$on('PageEventUp', (data) => this.on_childTriggePageEvent(data));
    this.logic_queryPageData();
  },
  destroyed() {
    PageEventBus.$off('PageEventUp', {});
  },
};
</script>

<style scoped>
.setting-panel {
  padding: 12px;
  margin: 4px 4px 4px 0px;
  background-color: #ffffff;
  width: 300px;
  height: 100%;
}
.mr-form-row {
  display: flex;
  flex-direction: row;
}
.mr-form-row mr-input {
  flex: 1;
}
.customWidth {
  width: 500px;
}
.page-chart-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.chart-border-editting {
  padding: 20px 12px 12px 12px;
}
.container-border-editting {
  padding: 20px 0px 0px 0px;
}

.local-modal >>> .ant-form-item {
  margin-bottom: 12px;
}

</style>
