<template>
  <div class="display-container" :class="{ border: showBorder }">
    <!-- chart area -->
    <div style="flex: 1; display: flex; flex-direction: column;">
      <div v-if="!loading" style="height: 100%;">
        <highcharts
          v-if="renderConfig.stockOptions"
          style="height: 100%;"
          :constructor-type="'stockChart'"
          :options="renderConfig.stockOptions"/>
      </div>

      <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
        <div style="font-size: 24px;">
        <i class="el-icon-loading"></i>
        </div>
      </div>

    </div>

    <!-- setting area -->
    <div class="setting-panel" v-if="showConfig">
      <div class="setting-section">
        <div class="setting-item">
          <span style="padding: 0px 4px 0px 0px;"> Indicator: </span>
          <select v-model="chartConfig.indicatorKey" >
            <option v-for="paramKey in Object.keys(inIndicators)" :key="paramKey"> {{ paramKey }} </option>
          </select>
        </div>
        <div v-if="chartConfig.indicatorKey" class="setting-item">
          <span style="padding: 0px 4px 0px 0px;"> X Axis: </span>
          <select v-model="chartConfig.xAxis" >
            <option v-for="field in inIndicators[chartConfig.indicatorKey].meta.fields"
                    :key="'A' + field.field"> {{ field.name }} </option>
          </select>
        </div>
        <div v-if="chartConfig.indicatorKey" class="setting-item">
          <span style="padding: 0px 4px 0px 0px;"> Open: </span>
          <select v-model="chartConfig.open" >
            <option v-for="field in inIndicators[chartConfig.indicatorKey].meta.fields"
                    :key="'B' + field.field"> {{ field.name }} </option>
          </select>
        </div>
        <div v-if="chartConfig.indicatorKey" class="setting-item">
          <span style="padding: 0px 4px 0px 0px;"> High: </span>
          <select v-model="chartConfig.high" >
            <option v-for="field in inIndicators[chartConfig.indicatorKey].meta.fields"
                    :key="'C' + field.field"> {{ field.name }} </option>
          </select>
        </div>
        <div v-if="chartConfig.indicatorKey" class="setting-item">
          <span style="padding: 0px 4px 0px 0px;"> Low: </span>
          <select v-model="chartConfig.low" >
            <option v-for="field in inIndicators[chartConfig.indicatorKey].meta.fields"
                    :key="'D' + field.field"> {{ field.name }} </option>
          </select>
        </div>
        <div v-if="chartConfig.indicatorKey" class="setting-item">
          <span style="padding: 0px 4px 0px 0px;"> Close: </span>
          <select v-model="chartConfig.close" >
            <option v-for="field in inIndicators[chartConfig.indicatorKey].meta.fields"
                    :key="'E' + field.field"> {{ field.name }} </option>
          </select>
        </div>

        <HR align=center width=260 color=#987cb9 style="margin: 12px 0px 8px 0px"/>
        <div v-if="chartConfig.indicatorKey" class="setting-item">
          <span style="padding: 0px 4px 0px 0px;"> Side Chart Count: </span>
          <a-input-number v-model="chartConfig.sideChartCount" :min="0" :max="4" size="small" />
        </div>

        <!-- lines -->
        <div v-for="line in chartConfig.lines" :key="line.id">
          <HR align=center width=260 color=#987cb9 style="margin: 12px 0px 8px 0px"/>
          <div v-if="line.indicatorKey!==''" class="setting-item">
            <span style="padding: 0px 4px 0px 0px;"> Display in: </span>
            <select v-model="line.chartIndex"  style="width: 100px">
              <option value="-1" > Main Chart </option>
              <option v-for="index in chartConfig.sideChartCount" :value="`${index}`"
                      :key="line.id + 'C' + index"> Side Chart{{ index }} </option>
            </select>
            <mr-icon-button name="" icon="trash-fill" size="14px"
                style="margin-left: 70px"
                @click="on_removeLine(line.id)"
                />
          </div>

          <!-- line select indicator -->
          <div class="setting-item">
            <span style="padding: 0px 4px 0px 0px;"> Indicator: </span>
            <select v-model="line.indicatorKey" style="width: 80px">
              <option v-for="paramKey in Object.keys(inIndicators)"
                      :key="line.id + paramKey"> {{ paramKey }} </option>
            </select>
            <span style="width: 8px;"/>
            <select v-model="line.type" style="width: 70px">
              <option value="line"> line </option>
              <option value="column"> column </option>
              <option value="scatter"> scatter </option>
              <option value="flags"> flags </option>
            </select>
          </div>

          <!-- line x & y -->
          <div v-if="line.indicatorKey!==''" class="setting-item">
            <span style="padding: 0px 4px 0px 0px;"> X Axis: </span>
            <select v-model="line.xAxis" style="width: 70px">
              <option v-for="field in inIndicators[line.indicatorKey].meta.fields"
                      :key="line.id + 'A' + field.field"> {{ field.name }} </option>
            </select>
            <span style="width: 8px;"/>

            <div v-if="line.type !== 'flags'">
              <!-- 非 flags配置Y -->
              <span style="padding: 0px 4px 0px 0px;"> Y Axis: </span>
              <select v-model="line.yAxis"  style="width: 70px">
                <option v-for="field in inIndicators[line.indicatorKey].meta.fields"
                        :key="line.id + 'B' + field.field"> {{ field.name }} </option>
              </select>
            </div>
            <div v-else>
              <!-- flags 选择依赖的图形 -->
              <span style="padding: 0px 4px 0px 0px;"> Flag Title: </span>
              <select v-model="line.flagTitle"  style="width: 70px">
                <option v-for="field in inIndicators[line.indicatorKey].meta.fields"
                        :key="line.id + 'B' + field.field"> {{ field.name }} </option>
              </select>
            </div>
          </div>
        </div>

        <div class="setting-item">
            <button style="width: 100%;" @click="on_addLine">Add Series</button>
        </div>

        <div class="setting-item">
          <button style="width: 100%;" @click="on_applySetting">Apply</button>
          <span style="width: 8px"/>
          <button style="width: 100%;" @click="on_saveSetting">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  remote_queryNode, remote_saveChartSettings, remote_queryNodeExeResult,
} from '@/script/StreamOperations';
import MrIconButton from '@/components/common/MrIconButton.vue';
import { PageEventBus } from '@/script/PageEventBus';

export default {
  components: { MrIconButton },
  name: 'CandlestickChartDisplay',
  props: [
    'streamId', 'nodeId', 'showConfig', 'showBorder', 'callMode',
    'interactEvents', 'interactActions', 'pageVariables',
  ],
  data() {
    return {
      chartConfig: { },
      renderConfig: { },
      loading: false,
      inIndicators: { },
    };
  },
  methods: {
    getInteractEvents() {
      /* 获取交互事件，会被父节点调用 */
      return [ {
        category: 'COMMON',
        event: 'LOADED',
        options: { },
        bind: { pageEvent: '', field: '', pageVariable: '', }
      } ];
    },
    getInteractActions() {
      return [ {
        category: 'COMMON',
        action: 'LOAD',
        options: { },
        // loadParams: [ {pageVariableName: '', paramName: ''} ]
        bind: { pageEvent: '', loadParams: [ ], },
      }];
    },
    on_recievePageEvent(data) {
      console.log('on_recievePageEvent');
      console.log(data);
      const pageEvent = data.pageEvent;
      const actions = this.interactActions.filter((it) => it.bind.pageEvent === pageEvent);
      actions.forEach((a) => {
        if (a.category === 'COMMON' && a.action === 'LOAD') {
          const params = { };
          a.bind.loadParams.forEach((p) => {
            params[p.paramName] = this.pageVariables[p.pageVariableName];
          });
          // do load
          this.loading = true;
          remote_queryNodeExeResult(this.streamId, this.nodeId, this.callMode, params).then((result) => {
            this.inIndicators = result.data.context.IN;
            this.on_applySetting();
            this.loading = false;
          });
        }
      });
    },
    on_addLine() {
      let maxIdLine = this.chartConfig.lines.reduce((pre, cur) => (pre.id > cur.id ? pre : cur), { id: 0 });
      const maxId = maxIdLine.id;
      this.chartConfig.lines.push({
        id: maxId + 1,
        indicatorKey: '',
        type: 'line',
        xAxis: '',
        yAxis: '',
        chartIndex: '-1',
      });
    },
    on_removeLine(id) {
      const newLines = [ ];
      this.chartConfig.lines.forEach((it) => {
        if (it.id !== id) {
          newLines.push(it);
        }
      });
      this.chartConfig.lines = newLines;
    },
    on_saveSetting() {
      remote_saveChartSettings(this.nodeId, this.chartConfig).then((result) => {
        this.$message({ message: result.message, type: 'success' });
      });
    },
    on_applySetting() {
      this.renderConfig = this.logic_buildCandleStickOption(this.chartConfig);
    },
    logic_buildCandleStickOption(config) {
      if (!config.indicatorKey) {
        return { stockOptions: { } };
      }
      const data = [ ];
      this.inIndicators[config.indicatorKey].data.forEach((it) => {
        data.push([
          it[config.xAxis],
          it[config.open],
          it[config.high],
          it[config.low],
          it[config.close],
        ]);
      });
      const lineSeries = [ ];
      const colors = [ '#3E97F8', '#F19737', '#8747f6', '#BE2F2A' ];
      config.lines.forEach((lineConfig) => {
        lineSeries.push(this.logic_buildLineData(lineConfig, colors.shift()));
      });

      const result = {
        type: config.type,
        stockOptions: {
          // title: { text: this.inIndicators[config.indicatorKey].name },
          plotOptions: {
            candlestick: {
              tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
                  'Open: {point.open}<br/>' +
                  'High: {point.high}<br/>' +
                  'Low: {point.low}<br/>' +
                  'Close: {point.close}<br/>',
              }
            }
          },
          series : [ {
            id: 'mainSeries',
            name : this.inIndicators[config.indicatorKey].name,
            type: 'candlestick',
            lineColor: 'green',
            upColor: 'red',
            upLineColor: 'red',
            data : data,
            tooltip: { valueDecimals: 2 },
          }, ...lineSeries ],
          rangeSelector: {
            buttons: [{
              type: 'month',
              count: 1,
              text: '1m',
            }, {
              type: 'month',
              count: 3,
              text: '3m',
            }, {
              type: 'month',
              count: 6,
              text: '6m',
            }, {
              type: 'month',
              count: 9,
              text: '9m',
            }, {
              type: 'year',
              count: 1,
              text: '1y',
            }, {
              type: 'month',
              count: 18,
              text: '18m',
            }, {
              type: 'year',
              count: 3,
              text: '3y',
            }],
            selected: 2,
          },
          tooltip: {
            dateTimeLabelFormats: {
              second: '%Y-%m-%d<br/>%H:%M:%S',
              minute: '%Y-%m-%d<br/>%H:%M',
              hour: '%Y-%m-%d<br/>%H:%M',
              day: '%Y<br/>%m-%d',
              week: '%Y<br/>%m-%d',
              month: '%Y-%m',
              year: '%Y',
            },
            split: false,
            shared: true,
            positioner() {
              return { x: 400, y: 0 };
            },
          },
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              second: '%Y-%m-%d<br/>%H:%M:%S',
              minute: '%Y-%m-%d<br/>%H:%M',
              hour: '%Y-%m-%d<br/>%H:%M',
              day: '%Y/%m/%d',
              week: '%Y/%m/%d',
              month: '%Y-%m',
              year: '%Y',
            },
          },
          navigator: {
            enabled: true,
            height: 20,
          },
          credits: {
            enabled: false,
          },
        },
      };
      if (this.chartConfig.sideChartCount > 0) {
        const sideChartHeight = 11;
        const gap = 0;
        const chartHeights = [ ];
        for (let i=0; i<this.chartConfig.sideChartCount; ++i) {
          chartHeights.push(sideChartHeight);
        }
        const totalSideChartHeight = this.chartConfig.sideChartCount * sideChartHeight;
        const mainChartHeight = 100 - totalSideChartHeight;
        const yAxis = [{
          labels: { align: 'right', x: -3 },
          height: `${mainChartHeight}%`,
          resize: { enabled: true },
          lineWidth: 1,
        }];
        let currentSum = mainChartHeight;
        for (let i=0; i<this.chartConfig.sideChartCount; ++i) {
          yAxis.push({
            labels: { align: 'right', x: -3 },
            top: `${currentSum + gap}%`,
            height: `${chartHeights[i]}%`,
            resize: { enabled: true },
            lineWidth: 1,
          });
          currentSum += (chartHeights[i] + gap);
        }
        result.stockOptions.yAxis = yAxis;
      }
      return result;
    },
    logic_buildLineData(lineConfig, color) {
      const data = [ ];
      this.inIndicators[lineConfig.indicatorKey].data.forEach((it) => {
        if (lineConfig.type !== 'flags') {
          data.push([
            it[lineConfig.xAxis],
            it[lineConfig.yAxis],
          ]);
        }
        else {
          data.push({
            x: it[lineConfig.xAxis],
            title: it[lineConfig.flagTitle],
          });
        }
      });
      const item = {
        // 通用name逻辑
        name: lineConfig.yAxis,
        type: lineConfig.type,
        data: data,
      };
      if (lineConfig.type === 'line') {
        item.lineWidth = 1;
      }
      if (lineConfig.type === 'flags') {
        // 修改name逻辑
        item.name = lineConfig.flagTitle;
        item.width = 16;
        // item.onSeries = 'mainSeries';
      }
      if (lineConfig.chartIndex !== '-1') {
        item.yAxis = parseInt(lineConfig.chartIndex);
      }
      if (color !== undefined) {
        item.color = color;
      }
      return item;
    },
    logic_loadSettings() {
      this.loading = true;
      this.chartConfig = {
        type: 'Candlestick',
        indicatorKey: '',
        xAxis: '',
        open: '',
        low: '',
        high: '',
        close: '',
        lines: [ ],
        sideChartCount: 0,
      };
      remote_queryNode(this.nodeId).then((result) => {
        if (result.data.chart_settings && result.data.chart_settings !== '{}') {
          this.chartConfig = JSON.parse(result.data.chart_settings);
        }
        this.on_applySetting();
        this.loading = false;
      });
    },
  },
  computed: {
    chartSelectOptions() {
      const result = [ { value: '-1', label: 'Main Chart' } ];
      for (let i=0; i<this.chartConfig.sideChartCount; ++i) {
        result.push({ value: `${i}`, label: `Side Chart${i+1}`});
      }
      return result;
    },
  },
  watch: {
    nodeId(newVal, oldValue) {
      this.logic_loadSettings();
    },
  },
  mounted() {
    PageEventBus.$on('PageEventDown', (data) => this.on_recievePageEvent(data) );
    remote_queryNodeExeResult(this.streamId, this.nodeId, this.callMode).then((result) => {
      this.inIndicators = result.data.context.IN;
      this.logic_loadSettings();
    });
  },
  destroyed() {
    PageEventBus.$off('PageEventDown', { });
  },
};
</script>

<style scoped>
.display-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.border {
  border: 1px solid #dadce0;
}
.setting-panel {
  width: 300px;
  height: 100%;
  border: 1px solid #dadce0;
  background-color: white;
  display: block;
  overflow: scroll;

  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-panel button {
  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-panel select {
  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-section {
  padding: 4px 12px 0px 12px;
  font-size: 11px;
}
.setting-item {
  display: flex;
  align-items: center;
  padding: 8px 0px 0px 0px;
}
.setting-item select, button {
  padding: 1px;
  border-width: 1px;
  border-radius: 3px;
}
.b-table-td {
  font-size: 12px;
}
</style>