import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../views/common/Login.vue';

import UsMacro from '@/views/data/UsMacro.vue';
import IndicatorManagement from '@/views/datamng/IndicatorManagement.vue';
import Spiders from '@/views/spider/Spiders.vue';

import DocRepos from '../views/doc/DocRepos.vue';
import RepoDetail from '../views/doc/RepoDetail.vue';
import Streams from '../views/stream/Streams.vue';
import StreamDetail from '../views/stream/StreamDetail.vue';
import Pages from '../views/page/Pages.vue';
import Page from '../views/page/Page.vue';
import Forms from '../views/page/Forms.vue';
import Form from '../views/page/Form.vue';
import SearchWord from '../views/dictionary/SearchWord.vue';
import ReviewWords from '../views/dictionary/ReviewWords.vue';
import Macro from '../views/macro/Macro.vue';

import CnStock from '@/views/data/CnStock.vue';

import Stock from '../views/deprecated/Stock.vue';
import Home from '../views/deprecated/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/macro',
    name: 'Macro',
    component: Macro,
  }, {
    path: '/home',
    name: 'Home',
    component: Home,
  }, {
    path: '/login',
    name: 'Login',
    component: Login,
  }, {
    path: '/stock',
    name: 'Stock',
    component: Stock,
  }, {
    path: '/docrepos',
    name: 'DocRepos',
    component: DocRepos,
  }, {
    path: '/repodetail/:repoid',
    name: 'RepoDetail',
    component: RepoDetail,
  }, {
    path: '/streams',
    name: 'Streams',
    component: Streams,
  }, {
    path: '/streamdetail/:streamid',
    name: 'StreamDetail',
    component: StreamDetail,
  }, {
    path: '/pages',
    name: 'Pages',
    component: Pages,
  }, {
    path: '/page/:pageid',
    name: 'Page',
    component: Page,
  }, {
    path: '/forms',
    name: 'Forms',
    component: Forms,
  }, {
    path: '/form/:formId',
    name: 'Form',
    component: Form,
  }, {
    path: '/searchword',
    component: SearchWord,
  }, {
    path: '/revieword',
    component: ReviewWords,
  }, {
    path: '/data/usmacro',
    component: UsMacro,
  }, {
    path: '/data/cnstock',
    component: CnStock,
  }, {
    path: '/indicatormng',
    component: IndicatorManagement,
  }, {
    path: '/spiders',
    component: Spiders,
  }
];

const router = new VueRouter({
  mode: 'history',
  // base: import.meta.env.BASE_URL,
  routes,
});

export default router;
