<template>
  <div id="body">
    <!-- 菜单 -->
    <mr-menu/>

    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            {{ name }}
          </p>
        </div>

        <div class="mr-row">
          <div class="mr-chart-common">
            <div style="width: 50%;">
              <a-form-item label="Submit API: " :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
                <a-input v-model="api" />
              </a-form-item>
            </div>
            <div style="display: flex; margin-top: 16px;">
              <codemirror
                ref="cmEditor"
                v-model="json"
                :options="cmOptions"
                style="flex: 1;"
                @ready="on_codeMirrorLoad"
                @input="on_saveScript"/>

              <div style="flex: 1;">
                <dynamic-form :schema="schema" :editMode="true">
                </dynamic-form>
              </div>
            </div>

            <a-button type="primary" style="margin-top: 16px;" @click="save">
              Save
            </a-button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MrMenu from '../../components/common/MrMenu.vue';
import FormAPIs from '@/script/FormAPIs';
import { codemirror } from 'vue-codemirror';
import DynamicForm from '../../components/deprecated/DynamicForm.vue';

export default {
  name: 'Form',
  components: { MrMenu, codemirror, DynamicForm, },
  data() {
    return {
      formId: '',
      name: '',
      api: '',
      json: '[]',

      cmOptions: {
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        // more CodeMirror options...
      },
    };
  },
  methods: {
    on_codeMirrorLoad() {
    },
    on_saveScript() {
    },
    save() {
      FormAPIs.updateSchema(this.formId, this.api, this.schema).then((responseData) => {
        this.$message({ message: responseData.message, type: 'success' });
      });
    },
  },
  computed: {
    schema() {
      return JSON.parse(this.json);
    },
  },
  mounted() {
    this.formId = this.$route.params.formId;
    FormAPIs.getById(this.formId).then((data) => {
      this.name = data.data.name;
      this.api = data.data.api;
      let json = JSON.stringify(data.data.schema, null, 2);
      if (json === '{}') {
        json = '[]';
      }
      this.json = json;
    });
  },
};
</script>

