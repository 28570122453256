<template>
  <div class="display-container" :class="{ border: showBorder }">
    <!-- chart area -->
    <div style="flex: 1; display: flex; flex-direction: column;">
      <div v-if="!loading" style="height: 100%;">
        <!-- Line -->
        <highcharts v-if="renderConfig.series"
          style="height: 100%;"
          :options="renderConfig"/>
      </div>

      <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
        <div style="font-size: 24px;">
        <i class="el-icon-loading"></i>
        </div>
      </div>
    </div>

    <!-- setting area -->
    <div class="setting-panel" v-if="showConfig">
      <div class="setting-section">
        <div v-for="line in chartConfig.lines" :key="line.id">
          <div class="setting-item">
            <span style="padding: 0px 8px 0px 0px;"> Indicator: </span>
            <select v-model="line.indicatorKey" >
              <option v-for="paramKey in Object.keys(inIndicators)"
                      :key="line.id + paramKey"> {{ paramKey }} </option>
            </select>
            <span style="width: 8px;"/>
            <select v-model="line.type" >
              <option value="line"> line </option>
              <option value="column"> column </option>
              <option value="scatter"> scatter </option>
            </select>

            <mr-icon-button name="" icon="trash-fill" size="14px"
                @click="on_removeLine(line.id)" >
            </mr-icon-button>

          </div>
          <div v-if="line.indicatorKey!==''" class="setting-item">
            <span style="padding: 0px 8px 0px 0px;"> X Axis: </span>
            <select v-model="line.xAxis" >
              <option v-for="field in inIndicators[line.indicatorKey].meta.fields"
                      :key="line.id + 'A' + field.field"> {{ field.name }} </option>
            </select>
          </div>
          <div v-if="line.indicatorKey!==''" class="setting-item">
            <span style="padding: 0px 8px 0px 0px;"> Y Axis: </span>
            <select v-model="line.yAxis" >
              <option v-for="field in inIndicators[line.indicatorKey].meta.fields"
                      :key="line.id + 'B' + field.field"> {{ field.name }} </option>
            </select>
          </div>
        </div>

        <div class="setting-item">
          <button style="width: 100%;" @click="on_addLine">Add Line</button>
        </div>

        <div class="setting-item">
          <button style="width: 100%;" @click="on_applySetting">Apply</button>
          <span style="width: 8px"/>
          <button style="width: 100%;" @click="on_saveSetting">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MrIconButton from '@/components/common/MrIconButton.vue';
import {
  remote_queryNode, remote_saveChartSettings, remote_queryNodeExeResult,
} from '@/script/StreamOperations';
import { PageEventBus } from '@/script/PageEventBus';

export default {
  components: { MrIconButton },
  name: 'LineChartDisplay',
  props: [
    'streamId', 'nodeId', 'showConfig', 'showBorder', 'callMode',
    'interactEvents', 'interactActions', 'pageVariables',
  ],
  data() {
    return {
      chartConfig: { },
      renderConfig: { },
      loading: false,
      inIndicators: { },
    };
  },
  methods: {
    getInteractEvents() {
      /* 获取交互事件，会被父节点调用 */
      return [ {
        category: 'COMMON',
        event: 'LOADED',
        options: { },
        bind: { pageEvent: '', field: '', pageVariable: '', }
      } ];
    },
    getInteractActions() {
      return [ {
        category: 'COMMON',
        action: 'LOAD',
        options: { },
        // loadParams: [ {pageVariableName: '', paramName: ''} ]
        bind: { pageEvent: '', loadParams: [ ], },
      }];
    },
    on_recievePageEvent(data) {
      console.log('on_recievePageEvent');
      console.log(data);
      const pageEvent = data.pageEvent;
      const actions = this.interactActions.filter((it) => it.bind.pageEvent === pageEvent);
      actions.forEach((a) => {
        if (a.category === 'COMMON' && a.action === 'LOAD') {
          const params = { };
          a.bind.loadParams.forEach((p) => {
            params[p.paramName] = this.pageVariables[p.pageVariableName];
          });
          // do load
          this.loading = true;
          remote_queryNodeExeResult(this.streamId, this.nodeId, this.callMode, params).then((result) => {
            this.inIndicators = result.data.context.IN;
            this.on_applySetting();
            this.loading = false;
          });
        }
      });
    },
    on_addLine() {
      let maxIdLine = this.chartConfig.lines.reduce((pre, cur) => (pre.id > cur.id ? pre : cur), { id: 0 });
      const maxId = maxIdLine.id;
      this.chartConfig.lines.push({
        id: maxId + 1,
        indicatorKey: '',
        type: 'line',
        xAxis: '',
        yAxis: '',
      });
    },
    on_removeLine(id) {
      const newLines = [ ];
      this.chartConfig.lines.forEach((it) => {
        if (it.id !== id) {
          newLines.push(it);
        }
      });
      this.chartConfig.lines = newLines;
    },
    on_saveSetting() {
      remote_saveChartSettings(this.nodeId, this.chartConfig).then((result) => {
        this.$message({ message: result.message, type: 'success' });
      });
    },
    on_applySetting() {
      if (Object.keys(this.inIndicators).length > 0) {
        this.renderConfig = this.logic_buildLineOption(this.chartConfig);
      }
    },
    logic_buildLineOption(config) {
      const series = [ ];
      let categoryMap = { };
      config.lines.forEach((lineConfig) => {
        const [data, categoryMap1] = this.logic_buildLineData(lineConfig);
        series.push(data);
        categoryMap = Object.assign(categoryMap, categoryMap1);
      });
      const renderConfig = {
        // chart: { type: 'column' },
        // type: config.type,
        series: series,
      };
      // 不是时间序列
      if (Object.keys(categoryMap).length > 0) {
        renderConfig.xAxis = { categories: Object.keys(categoryMap), };
      }
      return renderConfig;
    },
    logic_buildLineData(lineConfig) {
      const data = [ ];
      const categoryMap = { };
      const needCategory = !this.inIndicators[lineConfig.indicatorKey].meta.is_timeseries;
      this.inIndicators[lineConfig.indicatorKey].data.forEach((it) => {
        data.push([
          it[lineConfig.xAxis],
          it[lineConfig.yAxis],
        ]);
        if (needCategory) {
          categoryMap[it[lineConfig.xAxis]] = 1;
        }
      });
      const item = {
        name: lineConfig.yAxis,
        type: lineConfig.type,
        data: data,
      };
      if (lineConfig.type === 'line') {
        item.lineWidth = 1;
      }
      return [item, categoryMap];
    },
    logic_loadSettings() {
      this.loading = true;
      this.chartConfig = { type: 'Line', 'lines': [ ] };
      remote_queryNode(this.nodeId).then((result) => {
        if (result.data.chart_settings && result.data.chart_settings !== '{}') {
          this.chartConfig = JSON.parse(result.data.chart_settings);
        }
        this.on_applySetting();
        this.loading = false;
      });
    },
  },
  watch: {
    nodeId(newVal, oldValue) {
      this.logic_loadSettings();
    },
  },
  mounted() {
    PageEventBus.$on('PageEventDown', (data) => this.on_recievePageEvent(data) );
    remote_queryNodeExeResult(this.streamId, this.nodeId).then((result) => {
      if (result.data && result.data.context) {
        this.inIndicators = result.data.context.IN;
      }
      this.logic_loadSettings();
    });
  },
  destroyed() {
    PageEventBus.$off('PageEventDown', { });
  },
};
</script>

<style scoped>
.display-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.border {
  border: 1px solid #dadce0;
}
.setting-panel {
  width: 300px;
  height: 100%;
  border: 1px solid #dadce0;
  background-color: white;
  display: block;
  overflow: scroll;

  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-panel button {
  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-panel select {
  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-section {
  padding: 4px 18px 0px 18px;
  font-size: 11px;
}
.setting-item {
  display: flex;
  align-items: center;
  padding: 8px 0px 0px 0px;
}
.setting-item select, button {
  padding: 1px;
  border-width: 1px;
  border-radius: 3px;
}
.b-table-td {
  font-size: 12px;
}
</style>
