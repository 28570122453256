<template>
  <div id="app" class="app-basic">
    <div id="header" class="bt-sd">
      <div id="header-left">
        <a href="/home" class="logo-a">
          <img id="logo" src="./assets/logo-fire.png" />
        </a>
        <a>Mars Project</a>
      </div>
    </div>
    <mr-menu id="menu"
        :class="{'mu-pcwidth': !mobile, 'mu-mbwidth': mobile}"
        :mobile="mobile"
    />
    <div :class="{'main-pc': !mobile, 'main-mb': mobile}">
      <router-view />
    </div>
  </div>
</template>

<script>
import MrMenu from '@/components/common/MrMenu.vue';
export default {
  components: { MrMenu },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    this.mobile = document.body.clientWidth <= 900;
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import "./assets/fonts/cdoicons.css";

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0px;
  /*
  background-color: #f6f6f6;
  */
}
p {
	line-height: 1.4;
	font-weight: 400;
  margin-top: 0;
  margin-bottom: 6px!important;
  /*
  padding: 4px 0px 4px 0px;
  */
}
article, aside, details, figcaption, figure, footer, header,
hgroup, main, menu, nav, section, summary {
  display: block;
}
#app {
  /*
  display: flex;
  flex-direction: column;
  */
  width: 100%;
  height: 100%;
  /*
  margin-top: 60px;
  text-align: center;
  color: #2c3e50;
  */
}
.app-basic {
  color: #212529;
  /*
  font-family: Open Sans;
  */
  font-family:
    -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,
    "Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#header {
  object-fit: cover;
  display: block;
  height: 40px;
  width: 100%;
  background-color: rgb(37, 42, 61);
}
.bt-sd {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}
#menu {
  position: fixed;
  top: 40px;
  left: 0;
}
.mu-pcwidth {
  width: 130px;
}
.mu-mbwidth {
  width: 50px;
}
.main-pc {
  margin-left: 130px;
  margin-top: 0px;
  height: calc(100vh - 40px);
}
.main-mb {
  margin-left: 50px;
  margin-top: 0px;
  height: calc(100vh - 40px);
}
#header-left {
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}
#header-left > a {
  color: #ffffff;
}
#logo-a {
  display: flex;
  align-items: center;
}
#logo {
  height: 32px;
}
#body {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.header-wrapper {
  padding: 18px 15px 8px 15px;
}
.content, #content {
  flex: auto;
  background: rgb(245, 245, 245);
  /* height: calc(100vh - 40px);
  */
  overflow-y: scroll;
  height: 100%;
  /*
  background: rgb(73,74,95);
  */
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.mr-body {
  margin-left: 10em;
  margin-top: -1px;
  border: 1px solid #a7d7f9;
  border-right-width: 0;
  background-color: #ffffff;
}
.mr-main {
  display: flex;
  flex-direction: column;
  margin: 8px 4px;
}
.mr-row {
  display: flex;
}
.mr-card {
  display: flex;
  background: #ffffff;
}
.mr-noborder {
  display: flex;
}
.mr-scroll-container {
  display: flex;
  background: #ffffff;
  overflow: auto;
}
.mr-chart-common {
  flex: 1;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 12px;
  background-color: #ffffff;
}
.link-text {
  color: #335cad;
  /*
  color: #007BFF;
  */
  cursor: pointer;
}
.container-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.width100 {
  width: 100%;
}
.chart {
  background-color: #ffffff;
  box-shadow: 0 0px 27px 0 rgb(0 0 0 / 5%);
  border-radius: .3rem;
  padding: 12px 12px 1px 12px;
}
.roundcorner {
  border-radius: .3rem;
}
.thin-border {
  padding: 4px;
  border-radius: .3rem;
  margin: 4px;
  background-color: #ffffff;
}
.thin-border-no-bottom {
  padding: 4px 4px 0px 4px;
  border-radius: .3rem;
  margin: 4px;
  background-color: #ffffff;
}
.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(0, 0, 0, .125);
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
	border-radius: 0.3rem
}
.card-content {
  padding: 12px;
}
.chart-title {
  font-size: 17px;
  font-weight: 800;
  color: #212529;
}
.title-dec {
  position: relative;
}
.title-dec::before {
  content: "";
  width: 5px;
  height: 24px;
  position: absolute;
  top: 1;
  left: 0;
  background: #409EFF;
}
.minipadding {
  padding: 4px;
}
.compadding {
  padding: 12px;
}
.compadding2 {
  padding: 8px;
}
.compadding3 {
  padding: 12px;
}
.commargin {
  margin: 4px;;
}
.commargin2 {
  margin: 8px;;
}
.marginlr {
  margin-left: 4px;
  margin-right: 4px;
}
.margintb {
  margin-top: 4px;
  margin-bottom: 4px;
}
.margintb2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.margin-l {
  margin-left: 4px;
}
.margin-r {
  margin-right: 4px;
}
.margin-b {
  margin-bottom: 4px;
}
.margin-b2 {
  margin-bottom: 8px;
}
.marginb2 {
  margin-bottom: 8px;
}
.marginb {
  margin-bottom: 6px;
}
.marginb2 {
  margin-bottom: 12px;
}
.marginb3 {
  margin-bottom: 24px;
}
.margint {
  margin-top: 6px;
}
.margint2 {
  margin-top: 12px;
}
.margint3 {
  margin-top: 24px;
}
.marginl {
  margin-left: 6px;
}
.marginl2 {
  margin-left: 12px;
}
.marginl3 {
  margin-left: 24px;
}
.marginr {
  margin-right: 6px;
}
.marginr2 {
  margin-right: 12px;
}
.marginr3 {
  margin-right: 18px;
}
.marginr4 {
  margin-right: 24px;
}
.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-tb-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.tp-1 {
  padding-top: 4px;
}
.bp-1 {
  padding-bottom: 4px;
}
.t-red {
  color: red;
}
.page-title {
  font-size: 26px;
  font-weight: 400;
  color: '#515A6E';
  margin: 8px 0px;
}
.chart-title1 {
  font-size: 18px;
  font-weight: 400;
  color: '#515A6E';
}
.chart-title2 {
  font-size: 16px;
  font-weight: 400;
  color: '#515A6E';
}
.label-title {
  font-weight: 500;
  color: '#515A6E';
  display: inline-block;
  vertical-align: middle;
}
.flx1 {
  flex: 1;
}
.flxrow {
  display: flex;
  flex-direction: row;
}
.flxcolumn{
  display: flex;
  flex-direction: column;
}
.flxalignright {
  margin-left: auto;
}
.vtaligncenter {
  align-items: center;
}
.el-table thead {
  color: rgba(0, 0, 0, 0.75) !important;
}
.flexhcenter {
  display: flex;
  justify-content: center; /* 水平居中 */
}
mark, .mark {
  padding: 0.2em;
  background-color: #F2BD42;
}
.btn-p1 {
  padding: .2rem .6rem!important;
}
.btn-p2 {
  padding: .5rem .75rem!important;
}
.h1, h1 {
	font-size: 3rem!important;
	line-height: 1.25!important;
	letter-spacing: -.025rem!important;
  margin-top: 20px!important;
  margin-bottom: 10px!important;
}
.h2, h2 {
	font-size: 2.25rem!important;
	line-height: 1.3!important;
	letter-spacing: .05rem!important;
  margin-top: 20px!important;
  margin-bottom: 8px!important;
}
.h3, h3 {
	font-size: 1.875rem!important;
	line-height: 1.375!important;
  margin-top: 16px!important;
  margin-bottom: 8px!important;
}
.h4, h4 {
	font-size: 1.5rem!important;
	line-height: 1.375!important;
  margin-top: 16px!important;
  margin-bottom: 6px!important;
}
.h5, h5 {
	font-size: 1.25rem!important;
	line-height: 1.375!important;
  margin-top: 12px!important;
  margin-bottom: 4px!important;
}
.h6, h6 {
	font-size: 1rem!important;
	line-height: 1.625!important;
  margin-top: 12px!important;
  margin-bottom: 2px!important;
}
.xscroll-container {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: scroll;
}
.yscroll {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.yscroll-container {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.yscroll-container::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.tag {
  width: fit-content;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
}
.tag-red {
  color: rgb(93, 23, 21);
  background: rgb(255, 226, 221);
}
.tag-blue {
  color: rgb(24, 51, 71);
  background: rgb(211, 229, 239);
}
.tag-green {
  color: rgb(28, 56, 41);
  background: rgb(219, 237, 219);
}
.tag-yellow {
  color: rgb(64, 44, 27);
  background: rgb(253, 236, 200);
}
.tag-purple {
  color: rgb(65, 36, 84);
  background: rgb(232, 222, 238);
}
.tag-orange {
  color: rgb(73, 41, 14);
  background: rgb(250, 222, 201);
}
label {
  display: inline-flex !important;
  align-items: center;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  height: 32px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
  color-scheme: light;
}
label::after {
  position: relative;
  margin-block: 0;
  margin-inline-start: 2px;
  margin-inline-end: 8px;
}
.mr-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}
.clickable {
  cursor: pointer;
}
</style>
