<template>
  <div class="display-container" :class="{ border: showBorder }">
    <!-- chart area -->
    <div style="flex: 1; display: flex; flex-direction: column;">

      <!-- Table -->
      <div v-if="!loading" style="height: 100%; overflow-y: scroll;">
        <b-table :fields="renderConfig.fields"
            :items="rowItemProvider"
            :current-page="currentPage"
            :per-page="perPage"
            :small="true"
            selectable
            select-mode="single"
            :filter="filter"
            :filter-included-fields="filterOn"
            @row-clicked="on_rowClicked"
            @row-selected="on_rowSelected"
            ref="table"
            >
          <template v-slot:cell()="{ item, field: { key, unit } }">
            {{ `${item[key]}${unit ? unit : '' }` }}
          </template>

          <template #cell(_actions)="data">
            <div v-if="data" >
              <div v-for="action in data.value" :key="action.label">
                <span class="link-text" @click="action.callback($event.target)"
                  @RowNeedReRender="on_reRenderRow(data.item)" >
                  {{ action.label }}
                </span>
              </div>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="tableItems.length"
          :per-page="perPage"
          align="right" size="sm"
        ></b-pagination>
      </div>

      <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
        <div style="font-size: 24px;">
        <i class="el-icon-loading"></i>
        </div>
      </div>
    </div>

    <!-- setting area -->
    <div class="setting-panel" v-if="showConfig">
      <div class="setting-middle">
      <div class="setting-section">

        <div class="setting-item">
          <span style="padding: 0px 8px 0px 0px;"><strong>Indicator:</strong></span>
          <select v-model="chartConfig.indicatorKey">
            <option v-for="paramKey in Object.keys(inIndicators)"
                    :key="paramKey"> {{ paramKey }} </option>
          </select>
        </div>

        <div v-for="column in chartConfig.columns" :key="column.id">
          <div class="setting-item">
            <span style="padding: 0px 8px 0px 0px">Title:</span>
            <input v-model="column.title" style="width: 70px;">
            <span style="width: 8px;"/>

            <mr-icon-button name="" icon="trash-fill" size="14px"
                @click="on_removeColumn(column.id)">
            </mr-icon-button>
          </div>

          <div class="setting-item">
            <div v-if="chartConfig.indicatorKey!==''">
              <span style="padding: 0px 8px 0px 0px;"> Field: </span>
              <select v-model="column.indicatorField">
                <option v-for="field in inIndicators[chartConfig.indicatorKey].meta.fields"
                        :key="column.id + 'A' + field.field"> {{ field.name }} </option>
              </select>
              <span style="padding: 0px 8px 0px 8px">Unit:</span>
              <input v-model="column.unit" style="width: 40px;">
            </div>
          </div>
          <HR align=center width=260 color=#987cb9 style="margin: 8px 0px 4px 0px"/>
        </div>

        <div class="setting-item">
          <button style="width: 100%;" @click="on_addColumn">Add Column</button>
        </div>

        <div class="setting-item">
          <button style="width: 100%;" @click="on_editCustomRowFunction">Custom Row Function</button>
        </div>

        <div class="setting-item">
          <button style="width: 100%;" @click="on_applySetting">Apply</button>
          <span style="width: 8px"/>
          <button style="width: 100%;" @click="on_saveSetting">Save</button>
        </div>

      </div>
      </div>

    </div>

    <!-- 联动设置表单 -->
    <a-modal
        :visible="customRowScriptModalVisible"
        :width="800"
        @cancel="on_closeActionScriptModal"
        :footer="null"
        >
      <codemirror
          ref="cmEditor"
          v-model="customJsScript"
          :options="cmOptions"
          style="height: 400; width: 100%;"
          @ready="on_codeMirrorLoad"
          @input="on_editCustomScript"
      />
    </a-modal>

  </div>
</template>

<script>
import MrIconButton from '@/components/common/MrIconButton.vue';
import {
  remote_queryNode, remote_saveChartSettings, remote_queryNodeExeResult,
} from '@/script/StreamOperations';
import { BTable } from 'bootstrap-vue';
import { PageEventBus } from '@/script/PageEventBus';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/theme/monokai.css';
import { exeScriptString, } from '../../script/VmUtil.js';
import { smoothCall, } from '../../script/SmoothCallUtil.js';

export default {
  components: { MrIconButton, BTable, codemirror },
  name: 'TableChartDisplay',
  props: [
    'streamId', 'nodeId', 'showConfig', 'showBorder', 'callMode',
    'interactEvents', 'interactActions', 'pageVariables',
  ],
  data() {
    return {
      chartConfig: { },
      renderConfig: { },
      tableItems: [ ],
      currentPage: 1,
      perPage: 10,
      loading: false,
      inIndicators: { },
      customRowScriptModalVisible: false,

      // 当前编辑脚本的类型 Action ColumnColor
      customJsScriptType: '',
      customJsScript: '',
      customJsScriptColumnId: 0,
      customRowScriptDemo: '(row, callback) => { // callback(row); \n}',
      customRowFunc: undefined,

      cmOptions: {
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        // more CodeMirror options...
      },

      filter: null,
      filterOn: [ ],
    };
  },
  methods: {
    on_addColumn() {
      let maxIdColumn = this.chartConfig.columns.reduce((pre, cur) => (pre.id > cur.id ? pre : cur), { id: 0 });
      const newId = maxIdColumn.id + 1;
      this.chartConfig.columns.push({
        id: newId,
        title: '',
        unit: '',
        indicatorField: '',
        colorScript: '',
      });
    },
    on_removeColumn(id) {
      const newColumns = [ ];
      this.chartConfig.columns.forEach((it) => {
        if (it.id !== id) {
          newColumns.push(it);
        }
      });
      this.chartConfig.columns = newColumns;
      this.on_change();
    },
    on_editCustomRowFunction() {
      if (this.chartConfig.customRowScript === undefined) {
        this.chartConfig.customRowScript = this.customRowScriptDemo;
      }
      this.customJsScriptType = 'Row';
      this.customJsScript = this.chartConfig.customRowScript;
      this.customRowScriptModalVisible = true;
    },
    on_closeActionScriptModal() {
      this.customRowScriptModalVisible = false;
    },
    on_editCustomScript(text) {
      if (this.customJsScriptType === 'Row') {
        this.chartConfig.customRowScript = text;
      }
    },
    on_codeMirrorLoad() {
      console.log('on_codeMirrorLoad');
      this.$refs.cmEditor.codemirror.setSize(740, 400);
    },
    on_saveSetting() {
      remote_saveChartSettings(this.nodeId, this.chartConfig).then((result) => {
        this.$message({ message: result.message, type: 'success' });
      });
    },
    on_applySetting() {
      this.renderConfig = this.logic_buildTableOption(this.chartConfig);
    },
    logic_emitPageEvent(pageEvent, pageVariableName, pageVariableValue) {
      console.log(`logic_emitPageEvent: ${pageEvent}`);
      setTimeout(() => PageEventBus.$emit('PageEventUp', {
        pageEvent, pageVariableName, pageVariableValue,
      }), 100);
    },
    on_rowSelected(rows) {
      console.log("on_rowSelected:", rows);
    },
    on_rowClicked(row, index, e) {
      console.log("on_rowClicked:", row);
      if (e.target.className === 'link-text') {
        return;
      }
      if (this.interactEvents === undefined) {
        return;
      }
      const rowClickBinding = this.interactEvents.find((it) => it.category === 'TABLE' && it.event === 'ROW_SELECT');
      if (rowClickBinding) {
        const binding = rowClickBinding.bind;
        this.logic_emitPageEvent(
          binding.pageEvent, binding.pageVariable, binding.field === '$' ? row : row[binding.field],
        );
      }
    },
    on_recievePageEvent(data) {
      const pageEvent = data.pageEvent;
      const actions = this.interactActions.filter((it) => it.bind.pageEvent === pageEvent);
      actions.forEach((a) => {
        if (a.category === 'TABLE' && a.action === 'ROW_FILTER') {
          const field = a.bind.filterField;
          const value = this.pageVariables[a.bind.pageVariable];
          // do table filter
          this.filter = value;
          this.filterOn = [ field ];
        }
        if (a.category === 'COMMON' && a.action === 'LOAD') {
          const params = { };
          a.bind.loadParams.forEach((p) => {
            params[p.paramName] = this.pageVariables[p.pageVariableName];
          });
          // do load
          this.loading = true;
          remote_queryNodeExeResult(this.streamId, this.nodeId, this.callMode, params).then((result) => {
            this.inIndicators = result.data.context.IN;
            this.logic_loadSettings();
          }).catch((e) => {
            this.loading = false;
          });
        }
      });
    },
    getInteractEvents() {
      /* 获取交互事件，会被父节点调用 */
      return [ {
        category: 'COMMON',
        event: 'LOADED',
        options: { },
        bind: { pageEvent: '', }
      }, {
        category: 'TABLE',
        event: 'ROW_SELECT',
        options: { fields: this.logic_buildTableFields(), },
        bind: { pageEvent: '', field: '', pageVariable: '', }
      } ];
    },
    getInteractActions() {
      return [ {
        category: 'COMMON', action: 'LOAD', options: { },
        bind: { pageEvent: '', loadParams: [ ], },
      }, {
        category: 'TABLE', action: 'ROW_FILTER',
        options: { fields: this.logic_buildTableFields(), },
        bind: { pageEvent: '', filterField: '', pageVariable: '', }
      } ];
    },
    logic_buildTableFields() {
      if (this.chartConfig.type === 'Table') {
        const fields = [ ];
        // this.renderConfig.fields.forEach((it) => {
        //   fields.push({ label: it.label, key: it.key });
        // });
        if (this.inIndicators && this.chartConfig.indicatorKey &&
            this.inIndicators[this.chartConfig.indicatorKey]) {
          this.inIndicators[this.chartConfig.indicatorKey].meta.fields.forEach((it) => {
            fields.push({ label: it.name, key: it.field });
          });
        }
        return fields;
      }
      return [ ];
    },
    rowItemProvider(ctx) {
      console.log("rowItemProvider", ctx);
      const start = (ctx.currentPage - 1) * ctx.perPage;
      let end = ctx.currentPage * ctx.perPage;
      if (start >= this.tableItems.length) {
        console.log("rowItemProvider empty");
        return [];
      }
      if (end > this.tableItems.length) {
        end = this.tableItems.length;
      }
      if (ctx.sortBy !== undefined) {
        const compareValue = ctx.sortDesc === false ? 1 : -1;
        this.tableItems.sort((a, b) => {
          if (a[ctx.sortBy] < b[ctx.sortBy]) {
            return -compareValue;
          }
          if (a[ctx.sortBy] > b[ctx.sortBy]) {
            return compareValue;
          }
          return 0;
        });
      }
      console.log("rowItemProvider,", this.tableItems);
      const items = this.tableItems.slice(start, end);
      if (this.chartConfig.customRowScript) {
        this.customRowFunc = exeScriptString(this.chartConfig.customRowScript, { '$emit': this.$emit });
        const delayTasks = [ ];
        items.forEach((rowItem) => {
          delayTasks.push({
            func: this.customRowFunc,
            args: [
              rowItem,
              (result) => this.on_renderRow(rowItem, result),
              (a, b, c) => this.logic_emitPageEvent(a, b, c),
              () => {
                const index = this.tableItems.findIndex((i) => i.__id === rowItem.__id);
                this.tableItems.splice(index, 1);
                // 强制刷新
                this.logic_refreshRender();
                this.logic_selectRow(index);
                // console.log("on_rowClicked: ", index);
              },
            ],
          });
        });
        smoothCall(delayTasks, 50);
      }
      console.log("rowItemProvider,", items);
      return items;
    },
    on_reRenderRow(row) {
      if (this.customRowFunc) {
        this.customRowFunc(
          row,
          (result) => this.on_renderRow(row, result),
          (a, b, c) => this.logic_emitPageEvent(a, b, c),
          () => {
            const index = this.tableItems.findIndex((i) => i.__id === row.__id);
            this.tableItems.splice(index, 1);
            // 强制刷新
            this.logic_refreshRender();
            this.logic_selectRow(index);
          },
        );
      }
    },
    logic_selectRow(index) {
      setTimeout(() => {
        console.log("logic_selectRow: ", index);
        this.$refs.table.selectRow(index % this.perPage);
        this.on_rowClicked(this.tableItems[index], index, {
          target: { className: '' },
        });
      }, 100);
    },
    logic_refreshRender() {
      this.$refs.table.refresh();
    },
    logic_buildTableOption(config) {
      const fields = [ ];
      const cellVariantsTemp = { };
      config.columns.forEach((colConfig) => {
        cellVariantsTemp[colConfig.indicatorField] = 'light';
        fields.push({
          key: colConfig.indicatorField,
          label: colConfig.title,
          unit: colConfig.unit,
          sortable: true,
          tdClass: 'b-table-td',
        });
      });
      fields.push({ key: '_actions', label: 'Actions', tdClass: 'b-table-td', });
      this.tableItems = [ ];
      this.inIndicators[config.indicatorKey].data.forEach((it, index) => {
        this.tableItems.push({
          __id: index,
          ...it,
          _cellVariants: { ...cellVariantsTemp },
          _actions: [ ],
        });
      });
      console.log("==== set table items");
      return { type: config.type, fields: fields, };
    },
    on_renderRow(row, result) {
      if (result._cellVariants !== undefined) {
        for (let [ key, value ] of Object.entries(result._cellVariants)) {
          row._cellVariants[key] = value;
        }
      }
      if (result._actions !== undefined) {
        // 清空
        row._actions.length = 0;
        row._actions.push(...result._actions);
      }
    },
    logic_loadSettings() {
      this.loading = true;
      this.chartConfig = {
        type: 'Table',
        indicatorKey: '',
        columns: [ ],
        customRowScript: this.customRowScriptDemo,
      };
      remote_queryNode(this.nodeId).then((result) => {
        if (result.data.chart_settings && result.data.chart_settings !== '{}') {
          this.chartConfig = JSON.parse(result.data.chart_settings);
        }
        this.on_applySetting();
        this.loading = false;
      });
    },
  },
  watch: {
    nodeId(newVal, oldValue) {
      this.logic_loadSettings();
    },
  },
  mounted() {
    PageEventBus.$on('PageEventDown', (data) => this.on_recievePageEvent(data) );
    remote_queryNodeExeResult(this.streamId, this.nodeId, this.callMode).then((result) => {
      if (result.data) {
        this.inIndicators = result.data.context.IN;
        this.logic_loadSettings();
      }
    });
  },
  destroyed() {
    PageEventBus.$off('PageEventDown', { });
  },
};
</script>

<style scoped>
.display-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.border {
  border: 1px solid #dadce0;
}
.setting-panel {
  width: 300px;
  height: 100%;
  border: 1px solid #dadce0;
  background-color: white;
  display: block;
  overflow: hidden;

  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-panel button {
  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-panel select {
  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.setting-middle {
  width: 100%;
  height: 100%;
  padding-right: 17px;
  font-size: 11px;
  overflow-y: scroll;
  box-sizing: content-box;
}
.setting-section {
  padding: 4px 1px 0px 18px;
  font-size: 11px;
}
.setting-item {
  display: flex;
  align-items: center;
  padding: 8px 0px 0px 0px;
}
.setting-item select, button {
  padding: 1px;
  border-width: 1px;
  border-radius: 3px;
}
.b-table-td {
  font-size: 12px;
}
</style>