<template>
  <div style="display: flex; flex-direction: column;">
    <!-- header -->
    <div style="display: flex;">
      <span v-if="config.title && !hideTitle" style="fontSize: 16px; padding-bottom: 8px;">
        <strong>{{ config.title }}</strong>
      </span>
      <!-- 编辑态下加上编辑按钮header -->
      <div v-if="isEditting" style="margin-left: auto; display: flex;">
        <mr-icon-button name="Settings" icon="tools" size="11px"
            @click="on_editChartConfig(config.id)">
        </mr-icon-button>
        <mr-icon-button name="Delete" icon="trash-fill" size="11px"
            @click="on_deleteChart(config.id)" >
        </mr-icon-button>
      </div>
      <div v-else style="margin-left: auto; display: flex;">
        <mr-icon-button name="" icon="nut" size="12px" @click="on_openInteractSetting"> </mr-icon-button>
      </div>
    </div>

    <div class="chart-height">
    <line-chart-display
        v-if="nodeType==='LineChartNode'"
        :streamId="config.streamId"
        :nodeId="config.nodeId"
        :callMode="config.callMode"
        :showConfig="false"
        :showBorder="false"
        :interactEvents="interactEvents"
        :interactActions="interactActions"
        :pageVariables="pageVariables"
        ref="chartObj"
        >
    </line-chart-display>

    <candlestick-chart-display
        v-if="nodeType==='CandlestickChartNode'"
        :streamId="config.streamId"
        :nodeId="config.nodeId"
        :callMode="config.callMode"
        :showConfig="false"
        :showBorder="false"
        :interactEvents="interactEvents"
        :interactActions="interactActions"
        :pageVariables="pageVariables"
        ref="chartObj"
        >
    </candlestick-chart-display>

    <table-chart-display
        v-if="nodeType==='TableChartNode'"
        :streamId="config.streamId"
        :nodeId="config.nodeId"
        :callMode="config.callMode"
        :showConfig="false"
        :showBorder="false"
        :interactEvents="interactEvents"
        :interactActions="interactActions"
        :pageVariables="pageVariables"
        ref="chartObj"
        >
    </table-chart-display>
    </div>

    <!-- 联动设置表单 -->
    <a-modal
        :visible="interactSettingmodalVisible"
        :width="800"
        okText="Save"
        @cancel="on_closeInteractSetting"
        @ok="on_saveInteractSetting"
        >
      <interact-config
          :interactEvents="interactEvents"
          :interactActions="interactActions"
          :pageEvents="pageEvents"
          :pageVariables="pageVariables"
          @addPageEvent="on_addPageEvent"
          @addPageVariable="on_addPageVariable"
          >
      </interact-config>
    </a-modal>

  </div>
</template>

<script>
import {
  remote_queryNode,
} from '@/script/StreamOperations';
import {
  remote_saveChart,
} from '@/script/PageAPIs';
import LineChartDisplay from '../chartdisplay/LineChartDisplay.vue';
import CandlestickChartDisplay from '../chartdisplay/CandlestickChartDisplay.vue';
import TableChartDisplay from '../chartdisplay/TableChartDisplay.vue';
import MrIconButton from '@/components/common/MrIconButton.vue';
import InteractConfig from '@/components/deprecated/InteractConfig.vue';

export default {
  name: 'NodeChart',
  props: [ 'config', 'isEditting', 'pageEvents', 'pageVariables', 'hideTitle' ],
  components: {
    LineChartDisplay, CandlestickChartDisplay, TableChartDisplay, MrIconButton,
    InteractConfig,
  },
  data() {
    return {
      nodeType: '',
      interactSettingmodalVisible: false,
      interactEvents: [ ],
      interactActions: [ ],
    };
  },
  methods: {
    on_editChartConfig(chartId) {
      console.log('on_editChartConfig');
      this.$emit('editChartConfig', chartId);
    },
    on_deleteChart(chartId) {
      console.log(`on_editChartConfig ${chartId}`);
      this.$emit('deleteChart', chartId);
    },
    on_addPageEvent(eName) {
      this.$emit('addPageEvent', eName);
    },
    on_addPageVariable(vName) {
      this.$emit('addPageVariable', vName);
    },
    on_openInteractSetting() {
      this.interactSettingmodalVisible = true;
      const chartObj = this.$refs.chartObj;
      // 与已有的合并，以防chart有新增
      const events = chartObj.getInteractEvents();
      const actions = chartObj.getInteractActions();
      this.logic_updateInteractEvents(events);
      this.logic_updateInteractActions(actions);
    },
    on_closeInteractSetting() {
      this.interactSettingmodalVisible = false;
    },
    on_saveInteractSetting() {
      this.logic_saveChartConfig();
    },
    logic_updateInteractEvents(events) {
      const oldEvents = [ ];
      this.interactEvents.forEach((it) => {
          oldEvents[`${it.category}_${it.event}`] = it;
      });
      events.forEach((newE) => {
        const key = `${newE.category}_${newE.event}`;
        if (key in oldEvents) {
          // 数据更新
          if (newE.category === 'TABLE' && newE.event === 'ROW_SELECT') {
              oldEvents[key].options.fields = newE.options.fields;
          }
        } else {
          // 插入
          this.interactEvents.push(newE);
        }
      });
    },
    logic_updateInteractActions(actions) {
      const oldActions = [ ];
      this.interactActions.forEach((it) => {
          oldActions[`${it.category}_${it.action}`] = it;
      });
      actions.forEach((newA) => {
        const key = `${newA.category}_${newA.action}`;
        if (key in oldActions) {
          if (newA.category === 'TABLE' && newA.action === 'ROW_FILTER') {
            oldActions[key].options.fields = newA.options.fields;
          }
        } else {
          this.interactActions.push(newA);
        }
      });
    },
    logic_saveChartConfig() {
      const chartConfig = { ...this.config };
      chartConfig.interactEvents = this.interactEvents;
      chartConfig.interactActions = this.interactActions;
      remote_saveChart(chartConfig).then((result) => {
        this.$message({ message: result.message, type: 'success' });
      });
    },
  },
  mounted() {
    // 从保存的数据中初始化
    this.interactEvents = this.config.interactEvents ? this.config.interactEvents : [ ];
    this.interactActions = this.config.interactActions ? this.config.interactActions : [ ];

    remote_queryNode(this.config.nodeId).then((result) => {
      this.nodeType = result.data.node_type;
    });
  },
};
</script>

<style scoped>
.chart-height {
  height: -moz-calc(100% - 31px);
  height: -webkit-calc(100% - 32px);
  height: calc(100% - 32px);
}
</style>
