<template>
  <div id="body">

    <!-- 页面内容 -->
    <div id='content'>
      <div class="stream-detail-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="padding: 12px 8px 4px 8px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            {{ streamName }}
          </p>
            <div v-if="executeState" style="margin-left: 8px;" class="state-bar"
                :class="executeState==='PENDING'?'state-pending':(
                  executeState==='RUNNING'?'state-running':(
                    executeState==='SUCCESS'?'state-success':'state-error'))"
            >
              {{ executeState }}
            </div>
          <!-- head buttons -->
          <div style="margin-left: auto; display: flex;">
            <el-button size="mini" type="primary" round @click="onSubmitRun"> Run </el-button>
          </div>
        </div>

        <div class="edit-area">
          <div id="canvas-container-1">
            <div id="canvas-container-2">
              <div ref="streamCanvas" class="canvas"
                  :style="{'width': canvasWidth, 'height': canvasHeight}"
                  @drop="onDropNewNode($event)"
                  @dragover.prevent
                  @dragenter.prevent
              >
                <div :class="{'base-node': 1, 'node': 1,
                    'node-selected': it.uuid===curEditingNode.uuid}"
                    v-for="(it, nodeid) in nodesMap" :key="nodeid" :id="nodeid"
                    :style="{width: it.width + 'px', left: it.pos.left + 'px', top: it.pos.top + 'px'}"
                    @click="onClickNode(it.uuid)"
                    @contextmenu="onRightClickNode(it.uuid, $event)"
                    @dblclick="on_showChartDisplay(it.uuid)"
                    >

                  <div style="display: flex; align-items: center; justify-content: center;
                    height: 100%; width: 100%;">
                    <div v-show="executeResult[it.uuid] !== undefined"
                      :class="['dot-base',
                      executeResult[it.uuid] !== undefined && executeResult[it.uuid].state === 'SUCCESS_WITH_RESULT'
                      ? 'dot-success': 'dot-fail' ]">
                    </div>
                    <div style="margin: 0px 8px;">
                      {{ it.name }}
                    </div>
                  </div>
                </div>

              <context-menu :options="nodeLineMenuOptions"/>

              </div>
            </div>
          </div>
          <div class="setting-panel" :style="{'width': currentTab==='script'||currentTab==='exeResult'?'800px' :'400px'}">
            <div class="tab">
              <div :class="{'tablink': 1, 'tablink-active': currentTab==='setting'}"
                  @click="onSwitchSettingPane('setting')">Settings</div>
              <div v-show="curEditingNode.hasScript"
                  :class="{'tablink': 1, 'tablink-active': currentTab==='script'}"
                  @click="onSwitchSettingPane('script')">Script</div>
              <div v-show="executeResult[curEditingNode.uuid] !== undefined"
                  :class="{'tablink': 1, 'tablink-active': currentTab==='exeResult'}"
                  @click="onSwitchSettingPane('exeResult')">Exe Result</div>
              <div :class="{'tablink': 1, 'tablink-active': currentTab==='lib'}"
                  @click="onSwitchSettingPane('lib')">Node Lib</div>
            </div>

            <!-- Setting Tab -->
            <div :style="{'display': (currentTab==='setting' ? 'block' : 'none')}">
              <div class="setting-section">
                <div class="setting-section-title">
                  Basic
                </div>
                <div class="setting-item">
                  <div style="width: 40px;">Name: </div>
                  <input style="flex: 1;" v-model="curEditingNode.name" @change="on_editNode">
                </div>
              </div>
              <div class="setting-section">
                <div class="setting-section-title">
                  In Ports
                </div>
                <div class="setting-item"
                     v-for="it in curEditingNode.inPorts"
                     :key="it._id">
                  <input style="width: 60px;" v-model="it.name" @change="on_editPort(it._id, true)">
                  <div style="padding: 0px 4px;"> : </div>
                  <input style="flex: 1;" v-model="it.type_def" @change="on_editPort(it._id, true)">
                </div>
                <div class="setting-item">
                  <button style="width: 264px;" @click="on_addPort(true)">Add</button>
                </div>
              </div>
              <!-- OUT -->
              <div class="setting-section" v-show="curEditingNode.nodeType!=='ChartNode'">
                <div class="setting-section-title">
                  Out Ports
                </div>
                <div class="setting-item"
                     v-for="it in curEditingNode.outPorts"
                     :key="it._id">
                  <input style="width: 60px;" v-model="it.name" @change="on_editPort(it._id, false)">
                  <div style="padding: 0px 4px;"> : </div>
                  <input style="flex: 1;" v-model="it.type_def" @change="on_editPort(it._id, false)">
                </div>
                <div class="setting-item">
                  <button style="width: 264px;" @click="on_addPort(false)">Add</button>
                </div>
              </div>
              <!-- OPTIONS -->
              <div class="setting-section" v-show="curEditingNode.nodeType!=='ChartNode'">
                <div class="setting-section-title">
                  Options
                </div>
                <div class="setting-item"
                     v-for="it in curEditingNode.options"
                     :key="it._id">
                  <input style="width: 60px;" v-model="it.name" @change="on_editOption(it._id)">
                  <div style="padding: 0px 4px;"> : </div>
                  <input style="width: 40px; flex: 1;" v-model="it.type_def" @change="on_editOption(it._id)">
                  <div style="padding: 0px 4px;"> </div>
                  <input style="width: 100px; flex: 1;" v-model="it.value" @change="on_editOption(it._id)">
                </div>
                <div class="setting-item">
                  <button style="width: 264px;" @click="on_addOption">Add</button>
                </div>
              </div>
            </div>

            <!-- Script Tab -->
            <div :style="{'display': (currentTab==='script' ? 'block' : 'none')}">
              <!-- 脚本 -->
              <codemirror
                ref="cmEditor"
                v-model="curEditingNode.script"
                :options="cmOptions"
                style="height: 600px;width: 100%;"
                @ready="on_codeMirrorLoad"
                @input="on_saveScript"/>
            </div>

            <!-- Result -->
            <div :style="{'display': (currentTab==='exeResult' ? 'block' : 'none')}">
              <!-- 执行结果 -->
              <json-viewer v-show="executeResult[curEditingNode.uuid] !== undefined"
                :value="executeResult[curEditingNode.uuid]===undefined?{}:executeResult[curEditingNode.uuid]"
                class="mr-jv-code"
              />
            </div>

            <!-- Node Lib Tab -->
            <div :style="{'display': (currentTab==='lib' ? 'block' : 'none')}">
              <div class="setting-section">
                <div class="setting-section-title">
                  Empty Node
                </div>
                <div class="base-node lib-node" v-for="it in emptyNodeMeta" :key="it.name"
                    draggable="true" @dragstart="onDragStart($event, it.type)">
                  {{ it.name }}
                </div>
              </div>
              <div class="setting-section">
                <div class="setting-section-title">
                  Node Template
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
        :visible="showChartDisplay"
        :width="'80%'"
        :footer="null"
        @cancel="on_closeChartDisplay"
        >
      <div style="height: 600px;">
        <line-chart-display
          v-if="showChartNodeType ==='LineChartNode' && showChartDisplay"
          :streamId="streamid"
          :nodeId="showChartNodeId"
          :showConfig="true"
          :showBorder="true"
          >
        </line-chart-display>

        <candlestick-chart-display
          v-if="showChartNodeType ==='CandlestickChartNode' && showChartDisplay"
          :streamId="streamid"
          :nodeId="showChartNodeId"
          :showConfig="true"
          :showBorder="true"
          >
        </candlestick-chart-display>

        <table-chart-display
          v-if="showChartNodeType ==='TableChartNode' && showChartDisplay"
          :streamId="streamid"
          :nodeId="showChartNodeId"
          :showConfig="true"
          :showBorder="true"
          >
        </table-chart-display>
      </div>
    </a-modal>

  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { jsPlumb } from 'jsplumb';
import {
  queryStreamById, updateStreamRawGraph, createEmptyNode,
  queryStreamNodes, buildGraph, remote_queryNode, remote_addNewPort,
  convertModelToNode, updateNode, remote_updateParam, connectParam,
  removeConnection, removeNode, remote_saveScript,
  remote_submitExecute, remote_queryExecuteState, remote_queryStreamExeResult,
  remote_addNewOption, remote_updateOption,
} from '@/script/StreamOperations';
import ContextMenu from '@/components/deprecated/ContextMenu.vue';
import TableChartDisplay from '@/components/chartdisplay/TableChartDisplay.vue';
import LineChartDisplay from '@/components/chartdisplay/LineChartDisplay.vue';
import CandlestickChartDisplay from '@/components/chartdisplay/CandlestickChartDisplay.vue';
import JsonViewer from 'vue-json-viewer';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/python/python.js';
import 'codemirror/theme/monokai.css';

export default {
  name: 'StreamDetail',
  components: {
    ContextMenu, JsonViewer, codemirror,
    TableChartDisplay,
    LineChartDisplay,
    CandlestickChartDisplay,
  },
  data() {
    return {
      streamid: '',
      streamName: '',
      oriCanvasWidth: 0,
      oriCanvasHeight: 0,
      canvasWidth: '100%',
      canvasHeight: '100%',
      currentTab: 'setting',
      nodeToBeInit: '',
      executeState: '',
      executeResult: {},
      showChartDisplay: false,
      showChartNodeId: '',
      showChartNodeType: '',
      displayIndicators: [],

      cmOptions: {
        tabSize: 2,
        mode: 'text/x-python',
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        // more CodeMirror options...
      },

      // node map
      nodesMap: {},
      // {nodeId: {paramId: {nodeId: {paramId: connection}}}}
      connectionRefHub: {},
      nodeLineMenuOptions: {
        visible: false,
        left: 300,
        top: 200,
        items: [ { title: 'haha', icon: 'el-icon-delete', callback: () => {} } ],
      },
      curEditingNode: {
        uuid: '',
        name: '',
        inPorts: [ ],
        outPorts: [ ],
        options: [ ],
        nodeType: '',
        hasScript: false,
        script: 'hahaha',
      },
      scriptSaveQueue: [ ],
      hasSaveTask: false,

      jsPlumbIntsnace: '',
      jsPlumbSetting: {
        Container: 'canvas',
        Connector: [ 'Bezier', { curviness: 50 } ],
        ConnectionsDetachable: false,
        DeleteEndpointsOnDetach: false,
        Endpoint: [ 'Dot', { radius: 3 } ],
        PaintStyle: { stroke: '#c0c4ca', strokeWidth: 1 },
        EndpointStyle: { stroke: '#888', fill: '#fff' },
      },
      endpointOptions: {
        endpoint: [ 'Dot', { radius: 3 } ],
        style: { fill: 'blue' },
        maxConnections: -1,
        connector: [ 'Bezier', { curviness: 50 } ],
        connectorStyle: { strokeWidth: 1, stroke: '#b4b4b4' },
        scope: 'blueline',
        dropOptions: {
          drop(e, ui) {
            console.log(e);
            console.log(ui);
          },
        },
      },

      emptyNodeMeta: [ {
        name: 'ConstantNode',
        type: 'ConstantNode',
      }, {
        name: 'CustomScript',
        type: 'CustomNode',
      }, {
        name: 'Stream',
        type: 'StreamNode',
      }, {
        name: 'Collect',
        type: 'CollectNode',
      }, {
        name: 'LineChartNode',
        type: 'LineChartNode',
      }, {
        name: 'CandlestickChartNode',
        type: 'CandlestickChartNode',
      }, {
        name: 'TableChartNode',
        type: 'TableChartNode',
      } ],
    };
  },
  methods: {
    // ========================================================
    // 前端操作回调
    // ========================================================
    onDragStart(e, nodeType) {
      e.dataTransfer.setData('isEmptyNode', true);
      e.dataTransfer.setData('nodeType', nodeType);
    },
    onDropNewNode(e) {
      const isEmptyNode = e.dataTransfer.getData('isEmptyNode');
      if (isEmptyNode) {
        const type = e.dataTransfer.getData('nodeType');
        const nodeMeta = this.emptyNodeMeta.find((it) => it.type === type);
        this.createNodeFromEmpty(nodeMeta, e.offsetX, e.offsetY);
      }
    },
    onSwitchSettingPane(name) {
      this.currentTab = name;
      if (name === 'script') {
        setTimeout(() => {
          this.$refs.cmEditor.refresh();
        }, 300);
      }
    },
    onClickNode(uuid) {
      this.selectEditingNode(uuid);
    },
    on_editNode() {
      const nodeId = this.curEditingNode.uuid;
      updateNode({
        node_id: nodeId,
        name: this.curEditingNode.name,
      }).then((result) => {
        this.nodesMap[nodeId].name = this.curEditingNode.name;
      });
    },
    on_addPort(isIn) {
      const nodeid = this.curEditingNode.uuid;
      const name = isIn ? `in${this.curEditingNode.inPorts.length}` : `out${this.curEditingNode.outPorts.length}`;
      remote_addNewPort(nodeid, name, isIn ? 'in' : 'out').then((result) => {
        this.afterUpdateNode(nodeid);
      });
    },
    on_editPort(portId, isIn) {
      const nodeId = this.curEditingNode.uuid;
      let data = [ ];
      if (isIn) {
        data = this.curEditingNode.inPorts.find((it) => it._id === portId);
      } else {
        data = this.curEditingNode.outPorts.find((it) => it._id === portId);
      }
      remote_updateParam({ node_id: nodeId, param_id: portId, ...data }).then((result) => {
        this.afterUpdateNode(nodeId);
      });
    },
    on_addOption() {
      const nodeId = this.curEditingNode.uuid;
      const name = `opt${this.curEditingNode.options.length}`;
      remote_addNewOption(nodeId, name).then((result) => {
        this.afterUpdateNode(nodeId);
      });
    },
    on_editOption(optionId) {
      const nodeId = this.curEditingNode.uuid;
      const data = this.curEditingNode.options.find((it) => it._id === optionId);
      remote_updateOption({ node_id: nodeId, option_id: optionId, ...data }).then((result) => {
        this.afterUpdateNode(nodeId);
      });
    },
    onRightClickNode(nodeId, event) {
      event.preventDefault();
      this.showContextMenu(event.clientX, event.clientY, [ {
        icon: 'el-icon-delete',
        title: '删除',
        callback: () => {
          this.logic_removeNode(nodeId);
        },
      } ]);
    },
    onNewConnect(info, originalEvent) {
      connectParam(
        this.streamid, info.sourceId, info.sourceEndpoint.getUuid(),
        info.targetId, info.targetEndpoint.getUuid(),
      ).then(() => {
        this.addConnectionRef(info.sourceId, info.sourceEndpoint.getUuid(), info.targetId,
          info.targetEndpoint.getUuid(), info.connection);
      }).catch((result) => {
        this.jsPlumbIntsnace.deleteConnection(info.connection);
      });
    },
    on_saveScript(script) {
      if (!this.curEditingNode.uuid) {
        return;
      }
      this.scriptSaveQueue.push({ id: this.curEditingNode.uuid, script });
      if (!this.hasSaveTask) {
        this.hasSaveTask = true;
        setTimeout(() => this.logic_processSave(), 1000);
      }
    },
    logic_processSave() {
      if (this.scriptSaveQueue.length === 0) {
        this.hasSaveTask = false;
        return;
      }
      let task = this.scriptSaveQueue.shift();
      while (this.scriptSaveQueue.length !== 0) {
        if (task.id !== this.scriptSaveQueue[0].id) {
          break;
        }
        task = this.scriptSaveQueue.shift();
      }
      remote_saveScript(task.id, task.script).then((result) => {
        this.hasSaveTask = true;
        setTimeout(() => this.logic_processSave(), 1000);
      });
    },
    onSubmitRun() {
      remote_submitExecute(this.streamid).then((result) => {
        this.executeResult = {};
        this.logic_queryExecuteState();
      });
    },
    on_codeMirrorLoad() {
      console.log('on_codeMirrorLoad');
      this.$refs.cmEditor.codemirror.setSize(798, 700);
    },
    on_showChartDisplay(nodeId) {
      const nodeType = this.nodesMap[nodeId].nodeType;
      if (nodeType === 'LineChartNode' || nodeType === 'CandlestickChartNode' || nodeType === 'TableChartNode') {
        this.showChartDisplay = true;
        this.showChartNodeId = nodeId;
        this.showChartNodeType = nodeType;
        if (this.executeResult[nodeId] !== undefined) {
          this.displayIndicators = this.executeResult[nodeId].context.IN;
        } else {
          this.displayIndicators = [];
        }
      }
    },
    on_closeChartDisplay() {
      this.showChartDisplay = false;
    },
    // ========================================================
    // 内部函数
    // ========================================================
    afterUpdateNode(nodeId) {
      const x = this.nodesMap[nodeId].pos.left;
      const y = this.nodesMap[nodeId].pos.top;
      remote_queryNode(nodeId).then((result) => {
        const [ node, _ ] = convertModelToNode(result.data, x, y);
        this.setNodeByUuid(nodeId, node);
        this.data_setEdittingNode(result.data);

        this.jsPlumbIntsnace.removeAllEndpoints(nodeId);
        this.initPortsForNode(nodeId, node.inPorts, false);
        this.initPortsForNode(nodeId, node.outPorts, true);

        let lines = [ ];
        Object.entries(this.connectionRefHub).forEach((entry) => {
          const [ srcNodeId, obj ] = entry;
          Object.entries(obj).forEach((entry2) => {
            const [ srcParamId, obj2 ] = entry2;
            Object.entries(obj2).forEach((entry3) => {
              const [ desNodeId, obj3 ] = entry3;
              Object.entries(obj3).forEach((entry4) => {
                const [ desParamId, conn ] = entry4;
                if (srcNodeId === nodeId || desNodeId === nodeId) {
                  lines.push([ srcNodeId, srcParamId, desNodeId, desParamId ]);
                }
              });
            });
          });
        });
        this.logic_unbindConnectCallback();
        lines.forEach((line) => this.logic_connectLine(line));
        this.logic_bindConnectCallback();
      });
    },
    createNodeFromEmpty(meta, x, y) {
      createEmptyNode({
        stream_id: this.streamid,
        node_type: meta.type,
      }).then((result) => {
        const node = convertModelToNode(result.data, x, y)[0];
        this.appendNewNode(node);
        this.saveRawGraph();
        this.selectEditingNode(node.uuid);

        this.nodeToBeInit = node.uuid;
        this.onSwitchSettingPane('setting');
      });
    },
    selectEditingNode(nodeid) {
      this.curEditingNode.uuid = nodeid;
      remote_queryNode(nodeid).then((result) => {
        this.data_setEdittingNode(result.data);
        if (this.currentTab === 'script' && !this.curEditingNode.hasScript) {
          this.currentTab = 'setting';
        }
      });
    },
    deleteConnection(conn) {
      removeConnection(
        this.streamid, conn.sourceId, conn.endpoints[0].getUuid(), conn.targetId,
        conn.endpoints[1].getUuid(),
      ).then((result) => {
        this.removeConnectionRef(conn.sourceId, conn.endpoints[0].getUuid(), conn.targetId,
          conn.endpoints[1].getUuid());
        this.jsPlumbIntsnace.deleteConnection(conn);
      });
    },
    logic_removeNode(nodeId) {
      removeNode(nodeId).then((result) => {
        this.hideContextMenu();
        const obj = { ...this.connectionRefHub[nodeId] };
        Object.entries(obj).forEach((entry) => {
          const [ paramId, obj2 ] = entry;
          Object.entries(obj2).forEach((entry2) => {
            const [ nodeId2, obj3 ] = entry2;
            Object.entries(obj3).forEach((entry3) => {
              const [ paramId2, connection ] = entry3;
              this.jsPlumbIntsnace.deleteConnection(connection);
              this.removeConnectionRef(nodeId, paramId, nodeId2, paramId2);
            });
          });
        });

        delete this.nodesMap[nodeId];
        if (this.curEditingNode.uuid === nodeId) {
          this.data_clearEdittingNode();
        }
        this.jsPlumbIntsnace.removeAllEndpoints(nodeId);
      });
    },
    logic_queryExecuteState() {
      remote_queryExecuteState(this.streamid).then((result) => {
        if (result.data !== 'None') {
          this.executeState = result.data;
          if (result.data === 'PENDING' || result.data === 'RUNNING') {
            setTimeout(this.logic_queryExecuteState, 10000);
          }
          remote_queryStreamExeResult(this.streamid).then((result2) => {
            result2.data.forEach((it) => {
              this.executeResult[it.node_id] = it;
            });
          });
        }
      });
    },
    logic_connectLine(line) {
      const connection = this.jsPlumbIntsnace.connect({
        uuids: [ line[1], line[3] ],
        overlays: [ [ 'Arrow', { width: 5, length: 5, location: 1 } ] ],
      });
      this.addConnectionRef(...line, connection);
    },
    logic_bindConnectCallback() {
      this.jsPlumbIntsnace.bind('connection', this.onNewConnect);
    },
    logic_unbindConnectCallback() {
      this.jsPlumbIntsnace.unbind('connection');
    },
    // ========================================================
    // 数据操作
    // ========================================================
    getNodeByUuid(uuid) {
      return this.nodesMap[uuid];
    },
    setNodeByUuid(uuid, node) {
      this.nodesMap[uuid] = node;
    },
    appendNewNode(node) {
      this.nodesMap[node.uuid] = node;
    },
    addConnectionRef(nodeId, paramId, nodeId2, paramId2, connection) {
      if (!(nodeId in this.connectionRefHub)) {
        this.connectionRefHub[nodeId] = {};
      }
      if (!(paramId in this.connectionRefHub[nodeId])) {
        this.connectionRefHub[nodeId][paramId] = {};
      }
      if (!(nodeId2 in this.connectionRefHub[nodeId][paramId])) {
        this.connectionRefHub[nodeId][paramId][nodeId2] = {};
      }

      if (!(nodeId2 in this.connectionRefHub)) {
        this.connectionRefHub[nodeId2] = {};
      }
      if (!(paramId2 in this.connectionRefHub[nodeId2])) {
        this.connectionRefHub[nodeId2][paramId2] = {};
      }
      if (!(nodeId in this.connectionRefHub[nodeId2][paramId2])) {
        this.connectionRefHub[nodeId2][paramId2][nodeId] = {};
      }
      this.connectionRefHub[nodeId][paramId][nodeId2][paramId2] = connection;
      this.connectionRefHub[nodeId2][paramId2][nodeId][paramId] = connection;
    },
    removeConnectionRef(nodeId, paramId, nodeId2, paramId2) {
      delete this.connectionRefHub[nodeId][paramId][nodeId2][paramId2];
      delete this.connectionRefHub[nodeId2][paramId2][nodeId][paramId];
    },
    data_setEdittingNode(data) {
      this.curEditingNode = {
        uuid: data._id,
        name: data.name,
        inPorts: data.IN,
        outPorts: data.OUT,
        options: data.OPTIONS,
        nodeType: data.node_type,
        hasScript: data.has_custom_script,
        script: data.has_custom_script ? data.custom_script : 'haha',
      };
    },
    data_clearEdittingNode() {
      this.curEditingNode = {
        uuid: '',
        name: '',
        inPorts: [ ],
        outPorts: [ ],
        options: [ ],
        hasScript: false,
        script: '',
      };
    },
    saveRawGraph() {
      const posData = {};
      Object.values(this.nodesMap).forEach((it) => {
        posData[it.uuid] = it.pos;
      });
      updateStreamRawGraph(this.streamid, posData).then((data) => {
        // this.$message({ message: data.message, type: 'success' });
      });
    },
    showContextMenu(left, top, items) {
      this.nodeLineMenuOptions.visible = true;
      this.nodeLineMenuOptions.left = left - 160;
      this.nodeLineMenuOptions.top = top - 110;
      this.nodeLineMenuOptions.items = items;
      document.body.addEventListener('click', this.hideContextMenu);
    },
    hideContextMenu() {
      this.nodeLineMenuOptions.visible = false;
      document.body.removeEventListener('click', this.hideContextMenu);
    },
    // ========================================================
    // 初始化
    // ========================================================
    adjustCanvasSize() {
      let maxWidth = 0;
      let maxHieght = 0;
      Object.values(this.nodesMap).forEach((it) => {
        maxWidth = Math.max(maxWidth, it.pos.left);
        maxHieght = Math.max(maxHieght, it.pos.top);
      });
      this.canvasWidth = `${Math.max(maxWidth + 200, this.oriCanvasWidth)}px`;
      this.canvasHeight = `${Math.max(maxHieght + 100, this.oriCanvasHeight)}px`;
    },
    initPortsForNode(nodeId, ports, isSource) {
      const count = ports.length + 1;
      let index = 1;
      ports.forEach((ep) => {
        const epData = { ...this.endpointOptions };
        epData.uuid = ep.uuid;
        if (isSource) {
          epData.isSource = true;
          epData.anchor = [ index / count, 1, 0, 1 ];
          epData.overlays = [ [ 'Label', {
            label: ep.name, id: 'label', location: [ -2, 2 ],
            labelStyle: {
              color: '#787878',
            },
          } ] ];
        } else {
          epData.isTarget = true;
          epData.anchor = [ index / count, 0, 0, -1 ];
          epData.overlays = [ [ 'Label', {
            label: ep.name, id: 'label', location: [ -2, -1 ],
            labelStyle: {
              color: '#787878',
            },
          } ] ];
        }
        index = index + 1;
        this.jsPlumbIntsnace.addEndpoint(nodeId, epData);
      });
    },
    initNodeElement(node) {
      const vueObject = this;
      this.jsPlumbIntsnace.draggable(node.uuid, {
        stop(e) {
          vueObject.getNodeByUuid(node.uuid).pos = { left: e.pos[0], top: e.pos[1] };
          vueObject.saveRawGraph();
          vueObject.adjustCanvasSize();
        },
      });
      this.initPortsForNode(node.uuid, node.outPorts, true);
      this.initPortsForNode(node.uuid, node.inPorts, false);
    },
    loadJsPlumb(lines) {
      this.$nextTick().then(() => {
        jsPlumb.ready(() => {
          this.jsPlumbIntsnace = jsPlumb.getInstance();
          // this.jsPlumbIntsnace.overlayClass = 'endpoint-label';
          this.jsPlumbIntsnace.importDefaults(this.jsPlumbSetting);
          // node拖拽 & 端口
          Object.values(this.nodesMap).forEach((it) => {
            this.initNodeElement(it);
          });
          // 连线
          lines.forEach((line) => this.logic_connectLine(line));
          // repaint
          this.jsPlumbIntsnace.repaintEverything();
          this.logic_bindConnectCallback();
          this.jsPlumbIntsnace.bind('contextmenu', (component, originalEvent) => {
            originalEvent.preventDefault();
            this.showContextMenu(originalEvent.clientX, originalEvent.clientY, [ {
              icon: 'el-icon-delete',
              title: '删除',
              callback: () => {
                this.deleteConnection(component);
                this.hideContextMenu();
              },
            } ]);
          });
        });
      });
    },
  },
  mounted() {
    this.streamid = this.$route.params.streamid;
    queryStreamById(this.streamid).then((result) => {
      this.streamName = result.data.name;
      const rawGraph = JSON.parse(result.data.rawgraph !== '' ? result.data.rawgraph : '{}');
      queryStreamNodes(this.streamid).then((nodesResult) => {
        let lines = [];
        [ this.nodesMap, lines ] = buildGraph(
          nodesResult.data, rawGraph.posData !== undefined ? rawGraph.posData : {},
        );
        this.loadJsPlumb(lines);
      });
    });
    this.oriCanvasWidth = this.$refs.streamCanvas.clientWidth;
    this.oriCanvasHeight = this.$refs.streamCanvas.clientHeight;
    setTimeout(() => this.adjustCanvasSize(), 2000);

    this.logic_queryExecuteState();
  },
  updated() {
    this.$nextTick(() => {
      if (this.nodeToBeInit) {
        this.initNodeElement(this.nodesMap[this.nodeToBeInit]);
        this.nodeToBeInit = '';
      }
    });
  },
};
</script>

<style scoped>
.stream-detail-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.edit-area {
  display: flex;
  flex-direction: row;
  flex: 1;
  border-width: 4px 0px 4px 8px;
  border-style: solid;
  border-color: #f5f5f5;
}
#canvas-container-1 {
  height: 100%;
  flex: 1;
  display: block;
  position: relative;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #dadce0;
}
#canvas-container-2 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute!important;
  overflow: auto;
}
.canvas {
  position: relative;
  background-color: white;
  background-image:
    linear-gradient(#eee 1px, transparent 0),
    linear-gradient(90deg, #eee 1px, transparent 0),
    linear-gradient(#f5f5f5 1px, transparent 0),
    linear-gradient(90deg, #f5f5f5 1px, transparent 0);
  background-size: 75px 75px, 75px 75px, 15px 15px, 15px 15px;
}
.endpoint-overlay {
  font-size: 11px;
  color: red;
}
.node {
  width: 240px;
  height: 20px;
  position: absolute;
  border: 1px solid #6996f7;
  border-radius: 5px;
  background-color: white;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-family:
    -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,
    "Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.node-selected {
  background-color: rgba(227,244,255,.9);
  box-shadow: 0 0 3px 3px rbg(64, 169 255 / 20%);
}
.dot-base {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.dot-success {
  background-color: #52c41a;
}
.dot-fail {
  background-color: #f5222d;
}
.setting-panel {
  width: 600px;
  height: 100%;
  border: 1px solid #dadce0;
  background-color: white;
  display: block;

  font-family:Helvetica,Arial,sans-serif;
  color: rgb(112, 112, 112);
}
.setting-panel button {
  font-family:Helvetica,Arial,sans-serif;
  color: rgb(112, 112, 112);
  font-size: 11px;
}

.tab {
  width: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
}
.tablink {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 13px;
  font-weight: bold;
}
.tablink-active {
  background-color: white;
}
.setting-section {
  padding: 12px 0px 4px 18px;
  border-bottom: 1px solid #dadce0;
  border-color: #dadce0;
  font-size: 11px;
}
.setting-section-title {
  font-size: 12px;
  font-weight: bold;
  padding: 0px 0px 6px;
}
.setting-item {
  width: 264px;
  display: flex;
  align-items: center;
  padding: 0px 0px 8px 0px;
}
.code-editor {
  background-color: #272822;
  color: white;
}
.base-node {
  -moz-user-select: none;
  -o-user-select:none;
  -khtml-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.lib-node {
  width: 160px;
  height: 24px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 11px;
  margin-bottom: 12px;
  line-height: 12px;
  text-align: center;
}

.state-bar {
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
}
.state-pending {
  background-color: #87e8de;
}
.state-running {
  background-color: #91d5ff;
}
.state-success {
  background-color: #b7eb8f;
}
.state-error {
  background-color: #ffa39e;
}
.endpoint-label {
  font-size: 11px;
  font-family: -apple-system,
    BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
    Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.mr-jv-code {
  height: 600px;
  overflow: auto;
  padding: 8px 8px;
}
.CodeMirror {
  height: 100%;
}

</style>
